import Cookie from "js-cookie";
import {helpers} from '../global/setUserParamsLegacy';
import {is_w1, is_w2} from '../global/functions';
import {sendFacebookEvent} from '../global/facebookApiCalls';
import moment from 'moment';
import queryString from 'query-string';
import {autofillPageGatherInfo} from './personalInfoFirstFlow';
import {checkIfAppointmentIsInFuture} from './time';
/**
 * This method shows and adds the confirmation details block (for w1)
 *
 * @return null
 */
export function addConfirmationDetails( formID ) {

  $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString );

  // Test: Monthly Calendar 5/3/16
  if ( ! $( '#webFormCalendar' ).hasClass( 'cc-web-form-calendar-month' ) && ($( '#webFormCalendar2' ).length == 0 && $( '#webFormCalendar3' ).length == 0) ) {

    // Keep this section after the test is over
    var selectedCenter = ccUserInfo.availableCenters[ccUserInfo.centerID];
    global.ccUserInfo.addressString = '<h6>ClearChoice ' + selectedCenter.name + ' Center</h6>';
    global.ccUserInfo.addressString += '<p>' + $( '.callToday a' ).first().text() + '</p>';
    global.ccUserInfo.addressString += '<p>' + selectedCenter.address + ' ';
    global.ccUserInfo.addressString += selectedCenter.address2 + '<br/>';
    global.ccUserInfo.addressString += selectedCenter.city + ', ' + selectedCenter.state + ' ';
    global.ccUserInfo.addressString += selectedCenter.zipcode +	'</p>';

    var addressHTML = global.ccUserInfo.addressString;

    $( '#confirmation-details .appt-details .address' ).html( addressHTML );
    if ( $( '#temp_submit_button' ).length === 0 && $( '#gform_page_' + formID + '_' + ccUserInfo.currentStep ).is( ':last-child' ) ) {
      $( '.month-calendar-right .right-wrapper' ).append( '<input type="button" id="temp_submit_button" insp-form-input-id="submit-button" class="gform_next_button button" value="Schedule My Free Consultation" tabindex="21" >');
    }

    $( '#confirmation-details' ).slideDown();
  }
  // End Test 5/3/16

}

/**
 * returns default empty list for global.ccUserInfo
 */
export function getDefaultCCUserInfo() {
  return {
    guid: null,
    contactID: null,
    accountID: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    address: null,
    zipcode: null,
    city: null,
    state: null,
    centerID: null,
    availableCenters: {},
    availableAppointments: {},
    appointment: null,
    reschedulingAppointment: false,
    fewQuestions: {},
    trans_id : null, // used for ADI page
    referral_id : null, // this is the ID from the Dental_Practice__c table
    page: null,
    testing: null,
    isS20Active: null,
    isPartialLead: null,
  };
}

export function checkChangesToPreformData(){
  console.log(global.preFormInfo);
  console.log(global.ccUserInfo);
  if(global.preFormInfo == undefined ||
  global.preFormInfo.firstName != global.ccUserInfo.firstName ||
  global.preFormInfo.lastName != global.ccUserInfo.lastName ||
  global.preFormInfo.phone != global.ccUserInfo.phone ||
  global.preFormInfo.email != global.ccUserInfo.email ||
  global.preFormInfo.address != global.ccUserInfo.address ||
  global.preFormInfo.zipcode != global.ccUserInfo.zipcode ||
  global.preFormInfo.city != global.ccUserInfo.city ||
  global.preFormInfo.state != global.ccUserInfo.state){
    return false;
  } else{
    return true;
  }
}

/**
 * Sets global.ccUserInfo from the data received from the Partial form
 */
export function setGlobalFormData() {
  if (Cookie.get('cc-pre-form')) {
    getAndSetPreFormData();
  }else{
    global.retrieveLeadInfoLock = new $.Deferred();
    global.retrieveLeadInfoLock.resolve(false);
  }

  if($('body.adi-schedule').length > 0){
    global.ccUserInfo.page = 'adi';
  }else if($('body.colocation-schedule').length > 0){
    global.ccUserInfo.page = 'colocation';
  }else{
    global.ccUserInfo.page = 'os';
  }

  // Sets the ID of the ADI center if the url param 'trans_id' exists @todo: use getParam() instead
  let p = 'trans_id'; // url key name
  let match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  let p2 = 'referral_id'; // url key name
  let match2 = RegExp('[?&]' + p2 + '=([^&]*)').exec(window.location.search);
  let p3 = 'Center__c'; // url key name
  let match3 = RegExp('[?&]' + p3 + '=([^&]*)').exec(window.location.search);
  if(match)
    global.ccUserInfo.trans_id = decodeURIComponent(match[1].replace(/\+/g, ' '));
  if(match2)
    global.ccUserInfo.referral_id = decodeURIComponent(match2[1].replace(/\+/g, ' '));
  if(match3){
    global.ccUserInfo.colocation_center_c = decodeURIComponent(match3[1].replace(/\+/g, ' '));
  }
}


// set Google Analytics info for pushing to Sales Force
export function setGlobalGAInfo() {
    if (typeof ga !== 'undefined') {
      ga(function () {
        const tracker = ga.getAll()[0];
          global.ga.trackingId = tracker.get('trackingId');
          global.ga.clientId = tracker.get('clientId');
          global.ga.userId = Cookie.get('userId');
          global.ga.gclId = Cookie.get('cc-gclid');
        });
      } else {
          if(global.ga != undefined){
            global.ga.trackingId = global.ga.clientId = global.ga.userId = global.ga.gclId = '';
          }
    }
}


/**
 * Get user submitted data from Cookie (created from Partial form submission)
 */
export function getAndSetPreFormData() {
  var cookieDataString = Cookie.get('cc-pre-form');
  var cookieData = {};
  cookieData.action = "processCookieData";
  cookieData.cookieData = cookieDataString;
  cookieData.process = 'd';
  jQuery.ajax({
    url: ajaxurl,
    data: cookieData,
    cache: false,
    dataType: "json",
    type: "POST",
    success: function( result, success ) {
      var preFormData = $.parseJSON(result);
      if (preFormData) {
        global.ccUserInfo.firstName = preFormData.firstName;
        global.ccUserInfo.lastName = preFormData.lastName;
        global.ccUserInfo.phone = preFormData.phone;
        global.ccUserInfo.email = preFormData.email;
        global.ccUserInfo.address = preFormData.address;
        global.ccUserInfo.zipcode = preFormData.zipcode;
        global.ccUserInfo.city = preFormData.city;
        global.ccUserInfo.state = preFormData.state;
        global.preFormInfo = {};
        global.preFormInfo.firstName = preFormData.firstName;
        global.preFormInfo.lastName = preFormData.lastName;
        global.preFormInfo.phone = preFormData.phone;
        global.preFormInfo.email = preFormData.email;
        global.preFormInfo.address = preFormData.address;
        global.preFormInfo.zipcode = preFormData.zipcode;
        global.preFormInfo.city = preFormData.city;
        global.preFormInfo.state = preFormData.state;
        if($('form.schedule-consultation-form').length > 0){
          autofillPageGatherInfo();
          retrievePartialLeadInfoForFullForm();
        }
      }
    },
    error: function( result, error ) {
      console.log( 'Error: ', result );
    },
  });
}


export function getAndFillPreFormData() {
  var cookieDataString = Cookie.get('cc-pre-form');
  var cookieData = {};
  cookieData.action = "processCookieData";
  cookieData.cookieData = cookieDataString;
  cookieData.process = 'd';
  jQuery.ajax({
    url: ajaxurl,
    data: cookieData,
    cache: false,
    dataType: "json",
    type: "POST",
    success: function( result, success ) {
      var preFormData = $.parseJSON(result);
      if (preFormData) {
        $('.field-firstName input').val(preFormData.firstName);
        $('.field-lastName input').val(preFormData.lastName);
        $('.field-phone input').val(preFormData.phone);
        $('.field-email input').val(preFormData.email);
        $('.field-address input').val(preFormData.address);
        $('.field-zipcode input').val(preFormData.zipcode);
        $('.field-city input').val(preFormData.city);
        $('.field-state select').val(preFormData.state);
        $('#mobile-form .field-firstName input').val(preFormData.firstName);
        $('#mobile-form .field-lastName input').val(preFormData.lastName);
        $('#mobile-form .field-phone input').val(preFormData.phone);
        $('#mobile-form .field-email input').val(preFormData.email);
        $('#mobile-form .field-address input').val(preFormData.address);
        $('#mobile-form .field-zipcode input').val(preFormData.zipcode);
        $('#mobile-form .field-city input').val(preFormData.city);
        $('#mobile-form .field-state select').val(preFormData.state);
      }
    },
    error: function( result, error ) {
      console.log( 'Error: ', result );
    },
  });
}


 /**
  * sets checkbox and corresponding label states based on check settings
  */
  // all checkboxes other than none of the above
export var checkboxState = function() {
  var noneOfTheAbove = $(this).closest("ul").find("input[value='None of the Above']");

  if ( $(this).is(':checked') ) {
    $(this).parent().find('label').first().css('font-weight', 600);

    if ( noneOfTheAbove.is(':checked') ) {
      noneOfTheAbove.prop('checked', false);
      noneOfTheAbove.parent().find('label').first().css('font-weight', 400);
    }

  } else {
    $(this).parent().find('label').first().css('font-weight', 400);
  }
}

// none of the above checkboxes
export var noneOfTheAboveState = function() {
  var otherLocalCheckboxes = $(this).closest("ul").find('input[type=checkbox]').not($(this));

  if ( $(this).is(':checked') ) {
    $(this).parent().find('label').first().css('font-weight', 600);

    // uncheck otherLocalCheckboxes and normalize font-weight
    otherLocalCheckboxes.prop('checked', false);
    otherLocalCheckboxes.each( function() {
      $(this).parent().find('label').first().css('font-weight', 400);

      // hides any list items a checkbox might display when clicked
      $(this).parent().find('li').hide();
    });
  } else {
    $(this).parent().find('label').first().css('font-weight', 400);
  }
}
/**
   * Shows the message box for form completion errors
   *
   * @return null
   */
export function showMessageBox(){
  if( is_w1() ) {
    var $messageBox = $('#webform-component-messages');
    $('#webform-component-messages .call-me-now-box').css('display', 'none');
    $('#webform-component-messages .message-container').css('display', 'block');
    $messageBox.addClass('open').fadeIn();
  } else {
    $( '#web-form-modal' ).modal();
  }
}

/**
   * AJAX call to send User Communication preference for popup
   *
   * @return null
   */
   function sendUserTextConsent(){
    var leadData = {};
    if(global.ccUserInfo.guid != undefined && global.ccUserInfo.guid != ""){
      leadData.GUID = global.ccUserInfo.guid;
    }
    if(global.ccUserInfo.contactID != undefined && global.ccUserInfo.contactID != ""){
      leadData.GUID = global.ccUserInfo.contactID;
    }
    if(global.securityToken != undefined && global.securityToken != ""){
      leadData.securityToken = global.securityToken;
    }
    if( global.ccUserInfo.textConsent == true ){
      leadData.optIn = true;
    }
    if( global.ccUserInfo.textConsent == false ){
      leadData.optOut = true;
    }
    leadData.action = "cc_salesforce_add_text_preference_for_user";
    jQuery.ajax({
      url: ajaxurl,
      data: leadData,
      cache: false,
      dataType: "json",
      type: "POST",
      success: function(data) {
        console.log(data);
      },
      error : function(req, err) {
        console.log('Error:' + err);
      },
    });
  }

 /**
   * Helper function for ajax call to initiate Text Consent and collect preference
   *
   * @return null
   */
 export function showTextMessageTermsBox(){
  var messageBox = $('#webform-component-messages');
  $('#webform-component-messages .call-me-now-box').css('display', 'none');
  $('#webform-component-messages .message-container').css('display', 'block');
  // append new buttons and message header/text
  messageBox.find('.buttons').html('<button class="text-message-yes text-accept">Yes, I Agree</button></br><button class="text-message-no text-deny">No Thanks</button>');
  messageBox.find('.message-text').html('<h2 class="text-popup-hero">Get appointment reminders on your phone</h2>    I consent to receive autodialed and/or pre-recorded calls and text messages for any purpose, including appointment reminders and review requests, from or on behalf of ClearChoice at the number I provided. <br><br> I understand that i) my consent is not required as a condition of doing business with or receiving services from ClearChoice, ii) I may revoke this authorization at any time by calling 1-888-651-9950 or replying “STOP” to a text message from ClearChoice, and iii) standard text messaging rates will apply');
  // add classes for new CSS for the popup
  $('.message-text').addClass('text-popup-message');
  $('.message-container').addClass('text-popup-message-container');
  messageBox.addClass('open').fadeIn();
  $($('#webform-component-messages .text-accept')).one('click',function(){
    global.ccUserInfo.textConsent = true;
    sendUserTextConsent();
    global.dataLayer.push({ 'event': 'textConsented' });
    $('#webform-component-messages').addClass('close').fadeOut();
  });
  $($('#webform-component-messages .text-deny')).one('click',function(){
    global.ccUserInfo.textConsent = false;
    sendUserTextConsent();
    global.dataLayer.push({ 'event': 'textDenied' });
    $('#webform-component-messages').addClass('close').fadeOut();
  });
}

/**
   * Hides message box for form completion errors
   *
   * @return null
   */
export function hideMessageBox(id = '' ) {
  if( is_w1() ) {
    var $messageBox = $( '#webform-component-messages' );
    $messageBox.removeClass( 'open' ).fadeOut();
  } else {
    $(id).modal("toggle");
  }
}

/**
 * This method shows the "Call me now" module
 *
 * @return null
 */
export function showCallMeNow() {
  var $callmenowFirstName = $( '#call-me-now-firstName' );
  var $callmenowLastName = $( '#call-me-now-lastName' );
  var $callmenowPhone = $( '#call-me-now-phone' );

  $callmenowFirstName.val( global.ccUserInfo.firstName );
  $callmenowLastName.val( global.ccUserInfo.lastName );
  $callmenowPhone.val( global.ccUserInfo.phone );


  var $messageBox = $('#webform-component-messages');
  $('#webform-component-messages .call-me-now-box').css('display', 'block');
  $('#webform-component-messages .waiting-icon-container').css('display', 'none');
  $('#webform-component-messages .message-container').css('display', 'none');
  $messageBox.addClass('open').fadeIn();

  validateSection( 'call-me-now-section' );
  //initiate continue button
}
export function loadCallMeNowForm() {
  var $callmenowFirstName = $( '#call-me-now-firstName' );
  var $callmenowLastName = $( '#call-me-now-lastName' );
  var $callmenowPhone = $( '#call-me-now-phone' );
  var $messageBox = $('#call-me-now-form');
  $messageBox.find(".buttons .cancel").click(function(){
    hideMessageBox('#call-me-now-form');
  });

  $messageBox.find(".buttons .continue").click(function(){
    $messageBox.find(".buttons .continue").html("Please Wait...");
    $('.call-me-now-form-error').remove();
    //Validate the section and collect the errors
    var errors = validateSection( "call-me-now" );

    if(errors.length === 0){

      // $messageBox.find(".waiting-icon-container").slideDown(animationDuration);
      $messageBox.find(".call-me-now-form").slideUp();

      var leadData = {};

      leadData.action = "cc_salesforce_initiate_call_me_now";

      //If they're just repeating step one.
      if(global.ccUserInfo.guid !== ''){
        leadData.GUID = global.ccUserInfo.guid;
      }

      leadData.FirstName = $callmenowFirstName.val();
      leadData.LastName = $callmenowLastName.val();
      leadData.Phone = $callmenowPhone.val();

      if( global.ccUserInfo.address ){
        leadData.Address = global.ccUserInfo.address;
      }
      if( global.ccUserInfo.city ){
        leadData.City = global.ccUserInfo.city;
      }
      if( global.ccUserInfo.state ){
        leadData.State = global.ccUserInfo.state;
      }
      if( global.ccUserInfo.zipcode ){
        leadData.Zip = global.ccUserInfo.zipcode;
      }

      if( global.ccUserInfo.email !== ''){
        leadData.Email = global.ccUserInfo.email;
      }

      if( global.ccUserInfo.availableCenters && global.ccUserInfo.centerID){
        var currentCenterData = global.ccUserInfo.availableCenters[ global.ccUserInfo.centerID ];
        leadData.CenterId = currentCenterData.id;
      }
      leadData.testing = window.ccUserInfo.testing;

      jQuery.ajax({
        type: "POST",
        url: ajaxurl,
        data: leadData,
        success: function(){
          $messageBox.find(".message-text").html("<p>Thank you! You've successfully submitted your request. We look forward to speaking with you shortly.</p>");
          $messageBox.find('.buttons').html('<div class="row"><button class="btn btn-sm btn-outline-primary px-3 py-2 temp dismiss">Ok</a></div>');
          $messageBox.find(".buttons .dismiss").click(function(){
            hideMessageBox('#call-me-now-form');
          });

        },
        error: function(msg){
          // $messageBox.find(".waiting-icon-container").slideUp(animationDuration);
          $messageBox.find(".message-text").append("<p style='color: red;'>Sorry, something went wrong with your request. Try again later.</p>").slideDown();
          $messageBox.find('.buttons').html('<div class="row"><button class="btn btn-sm btn-outline-primary px-3 py-2 temp dismiss">Ok</a></div>');
          $messageBox.find(".buttons .dismiss").click(function(){
            hideMessageBox('#call-me-now-form');
          });

          global.dataLayer.push({ 'error_data': msg });
          global.dataLayer.push({ 'error_message': 'Ajax Error initiating Call-Me-Now' });
          global.dataLayer.push({ 'event': 'ajax-error' });
        },
      });
    }else{
      $messageBox.find(".buttons .continue").html("Continue");
      $.each(errors,function(index,error){
        $messageBox.find(".message-text").append('<div class="validation_error call-me-now-form-error">'+error+'</div>');
      });
    }
  });
}


/**
 * Hide "waiting" icon
 *
 * @return null
 */
export function hideWaitingIcon()	{
  var $loadingIcon = $( '#waiting-icon' );
  $loadingIcon.hide();
}

/**
 * Show "waiting" icon
 *
 * @return null
 */
export function showWaitingIcon( message ) {
  var $loadingIcon = $( '#waiting-icon' );
  $loadingIcon.find( '.message' ).html( message );
  $loadingIcon.show();
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ')' + match[2] + '-' + match[3];
  }
  return null;
}

  // DOBTest
  let birthDateErrorThrown = false;
/**
 * Validate form sections before passing to next form section/page
 *
 * @param  string   Passin the section string/name here
 *
 * @return object   Returns an error object for invalid sections
 */
export function validateSection(section) {
  var errors = [];
  let callCenterNumber = $(".trans_id").first().text() || '888-651-9950';
  switch(section){
    case 'page-gather-info':
      $( '.page-gather-info' ).find( 'input' ).removeClass( 'error' );

      if ( $( '.field-firstName input' ).val() === '' ) {
        errors.push( 'Please enter your first name.' );
        $( '.field-firstName input' ).addClass( 'error' );
      }

      if ( $('.field-lastName input' ).val() === '' ) {
        errors.push( 'Please enter your last name.' );
        $('.field-lastName input' ).addClass( 'error' );
      }

      var phonenumberVal = $( '.field-phone input' ).val();
      var phonenumber = phonenumberVal.replace(/\D/g,'');

      if ( phonenumber === '' ) {
        errors.push( 'Please enter your phone.' );
        $( '.field-phone input' ).addClass( 'error' );
      }

      if ( phonenumber.length !== 10 ) {
        errors.push( 'Only 10-digit phone numbers are supported.' );
        $( '.field-phone input' ).addClass( 'error' );
      }

      if ( phonenumber != undefined && phonenumber.length == 10 && (phonenumber[0] == "1" || phonenumber[0] == "0")) {
        errors.push( 'Not a valid phone number' );
        $( '.field-phone input' ).addClass( 'error' );
      }

      var emailVal = $( '.field-email input' ).val();
      if ( emailVal === '' ) {
        errors.push( 'Please enter your email.' );
        $( '.field-email input' ).addClass( 'error' );
      } else {

        var patt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if( ! patt.test( emailVal.trim() ) ) {
          errors.push( 'That doesn&rsquo;t seem to be a valid email address' );
          $( '.field-email input' ).addClass( 'error' );
        }
      }

      if ( $( '.field-address input' ).val() === '' ) {
        errors.push( 'Please enter your address.' );
        $( '.field-address input' ).addClass( 'error' );
      }

      if ( $( '.field-zipcode input' ).val() === '' ) {
        errors.push( 'Please enter your zipcode.' );
        $( '.field-zipcode input' ).addClass( 'error' );
      } else if ( $( '.field-zipcode input' ).hasClass('zipcode-error') ) {
        errors.push( 'Please enter a valid zipcode.' );
        $( '.field-zipcode input' ).addClass( 'error' );
      }

      if ( $( '.field-city input' ).val() === '' ) {
        errors.push( 'Please enter your city.' );
        $( '.field-city input' ).addClass( 'error' );
      }

      if ( $( '.field-state select' ).val() === '') {
        errors.push( 'Please enter your state.' );
        $( '.field-state select' ).addClass( 'error' );
      }

      break;

    case 'page-few-questions':
      if ( $( '.field-questions input:checked' ).length < 1 ) {
        errors.push( 'Please select at least one option' );
      }


      if ( $( '.field-questions input:checked' ).val() === 'None of the Above' && window.location.pathname.includes('colocation') != true) {
        errors.push( 'Sorry we’re unable to schedule a consultation online based on your current selections. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.' );
      }

    break;

    case 'page-datetime-confirm':
      $( '.page-datetime-confirm' ).find( 'input' ).removeClass( 'error' );
      //var testTextConfirmationPopUp = Cookie.get('testTextConfirmationPopUp');
      //var testTextConfirmationCheckBox = Cookie.get('testTextConfirmationCheckBox');
      //if(!testTextConfirmationCheckBox && !testTextConfirmationPopUp){
        if ( ! $( '.field-agree-to-terms input' ).prop( 'checked' ) ){
          errors.push( 'To continue, you must agree to the terms and conditions.' );
          $( '.field-agree-to-terms input' ).addClass( 'error' );
        }
      //}




      var selectedTimeSlot = $("#choose-date--timelist .choose-date-timelist ul > li.selected").data('timeslot');
      if(selectedTimeSlot == undefined || selectedTimeSlot == ""){
        errors.push( 'Please select a date and time.' );
      }

      // error checking for when birthdate is required
      // if ($( '#patient-birth-date' ).val() === ''){
      //   errors.push( 'Please enter a proper date of birth.' );
      // } else if(!checkFor18AndOlder()){
      //   errors.push( 'Must be 18 or older to submit form.' );
      // }



      var confirmBirthdayCheck = `<div style="display: flex; flex-direction: row; align-items: center; width: 100%; justify-content: center;">
          <input style="height: 30px; width: 30px;" type="checkbox" name="DOB-check-value" id="DOB-check-value"/>
          <p style="margin: 0; font-size: 14px !important; line-height: 1.4;">Check here to confirm that you prefer to not provide your birth date.</p>
      </div>`;

      break;

    case 'page-datetime':
      if ( ! ccUserInfo.appointment ) {
        errors.push( 'Please select a date and time' );
      }

    break;

    case 'call-me-now':
      if ( $( '#call-me-now-firstName' ).val() === '' ) {
        errors.push(' Please enter your first name.' );
      }

      if ( $( '#call-me-now-lastName' ).val() === '' ) {
        errors.push(' Please enter your last name.' );
      }

      if ( $( '#call-me-now-phone' ).val() === '' ) {
        errors.push(' Please enter your phone.' );
      }

    break;

    case 'page-call-me-later-submit':
      if ( ! $( '.field-agree-to-terms input' ).prop( 'checked' ) ) {
        errors.push( 'To continue, you must agree to the terms and conditions.' );
        $( '.field-agree-to-terms input' ).addClass( 'error' );
      }

      if ( global.ccUserInfo.appointment === null ) {
        errors.push( 'Please select a Date / Time.' );
      }
    break;

    default:

  }
  return errors;
}

export function validateSectionInlineMessaging(section) {
  $(".error-text, .bottom-error").remove();
  $(".gfield_error").removeClass("gfield_error");
  // RESKINNING FOR SCHEDULE NOW
  if(window.location.pathname.includes('schedule-now')){
    $(".gfield_now_error").removeClass("gfield_now_error");
    $(".gfield_error_now_override").removeClass("gfield_error_now_override");
  }

  var userPtc = false;
  var errors = [];
  var callCenterNumber;
  if (window.location.pathname.includes('/schedule-now')) {
    callCenterNumber = '888-486-3443';
  } else {
    callCenterNumber = $(".trans_id").first().text() || '888-651-9950';
  }

  switch(section){
    case 'page-gather-info':
      $( '.page-gather-info' ).find( 'input' ).removeClass( 'error' );

      if ( $( '.field-firstName input' ).val() === '' ) {
        errors.push( 'Please enter your first name.' );
        $( '.field-firstName input' ).addClass( 'error' );
        $( '.field-firstName input' ).parent().parent().append("<span class='error-text'>Please enter your first name.</span>");
      }

      if ( $('.field-lastName input' ).val() === '' ) {
        errors.push( 'Please enter your last name.' );
        $('.field-lastName input' ).addClass( 'error' );
        $('.field-lastName input' ).parent().parent().append("<span class='error-text'>Please enter your last name.</span>");
      }

      if($( '.field-birth-date-entry input' ).val() != '' && $( '.field-birth-date-entry input' ).val() != undefined){
        if(!verifyDateExists()){
          errors.push( 'Please enter a valid date.' );
          $( '.field-birth-date-entry input' ).addClass( 'error' );
          $( '.field-birth-date-entry input' ).parent().parent().append("<span class='error-text'>Please enter a valid date.</span>");
        }

        // if(!checkFor18AndOlder()){
        //   errors.push( 'Must be 18 or older to submit form.' );
        //   $( '.field-birth-date-entry input' ).parent().parent().append("<span class='error-text'>Must be 18 or older to submit form.</span>");
        //   errors.push( 'Sorry you must be 18 years or older to schedule yourself online. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.' );
        //   $("#gform_next_button_19_8").parent().append('<div class="bottom-error"><span>Sorry you must be 18 years or older to schedule yourself online. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.</span></div>');
        //   $("#gform_next_button_34_11").parent().append('<div class="bottom-error"><span>>Sorry you must be 18 years or older to schedule yourself online. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.</span></div>');


        //   userPtc = true;
        // }

        if(checkForUpperBound()){
          errors.push( 'Please enter a birthday on or after 01/01/1900.' );
          $( '.field-birth-date-entry input' ).addClass( 'error' );
          $( '.field-birth-date-entry input' ).parent().parent().append("<span class='error-text'>Please enter a birthday on or after 01/01/1900.</span>");
        }
      }else{
        errors.push( 'Please enter your birthdate.' );
        $( '.field-birth-date-entry input' ).addClass( 'error' );
        $( '.field-birth-date-entry input' ).parent().parent().append("<span class='error-text'>Please enter your birth date.</span>");
      }

      var phonenumberVal = $( '.field-phone input' ).val();
      var phonenumber = phonenumberVal.replace(/\D/g,'');

      if ( phonenumber === '' ) {
        errors.push( 'Please enter your phone.' );
        $( '.field-phone input' ).addClass( 'error' );
        $( '.field-phone input' ).parent().parent().append("<span class='error-text'>Please enter your phone.</span>");
      }else if ( phonenumber.length !== 10 ) {
        errors.push( 'Only 10-digit phone numbers are supported.' );
        $( '.field-phone input' ).addClass( 'error' );
        $( '.field-phone input' ).parent().parent().append("<span class='error-text'>Only 10-digit phone numbers are supported.</span>");
      }else if ( phonenumber != undefined && phonenumber.length == 10 && (phonenumber[0] == "1" || phonenumber[0] == "0")) {
        errors.push( 'Not a valid phone number' );
        $( '.field-phone input' ).addClass( 'error' );
        $( '.field-phone input' ).parent().parent().append("<span class='error-text'>Not a valid phone number.</span>");
      }

      var emailVal = $( '.field-email input' ).val();
      if ( emailVal === '' ) {
        errors.push( 'Please enter your email.' );
        $( '.field-email input' ).addClass( 'error' );
        $( '.field-email input' ).parent().parent().append("<span class='error-text'>Please enter your email.</span>");
      } else {

        var patt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if( ! patt.test( emailVal.trim() ) ) {
          errors.push( 'That doesn&rsquo;t seem to be a valid email address' );
          $( '.field-email input' ).addClass( 'error' );
          $( '.field-email input' ).parent().parent().append("<span class='error-text'>That doesn&rsquo;t seem to be a valid email address.</span>");
        }
      }

      if ( $( '.field-address input' ).val() === '' ) {
        errors.push( 'Please enter your address.' );
        $( '.field-address input' ).addClass( 'error' );
        $( '.field-address input' ).parent().parent().append("<span class='error-text'>Please enter your address.</span>");
      }

      if ( $( '.field-zipcode input' ).val() === '' ) {
        errors.push( 'Please enter your zipcode.' );
        $( '.field-zipcode input' ).addClass( 'error' );
        $( '.field-zipcode input' ).parent().parent().append("<span class='error-text'>Please enter your zipcode.</span>");
      } else if ( $( '.field-zipcode input' ).hasClass('zipcode-error') ) {
        errors.push( 'Please enter a valid zipcode.' );
        $( '.field-zipcode input' ).addClass( 'error' );
        $( '.field-zipcode input' ).parent().parent().append("<span class='error-text'>Please enter a valid zipcode.</span>");
      }

      if ( $( '.field-city input' ).val() === '' ) {
        errors.push( 'Please enter your city.' );
        $( '.field-city input' ).addClass( 'error' );
        $( '.field-city input' ).parent().parent().append("<span class='error-text'>Please enter your city.</span>");
      }

      if ( $( '.field-state select' ).val() === '') {
        errors.push( 'Please enter your state.' );
        $( '.field-state select' ).addClass( 'error' );
        $( '.field-state select' ).parent().parent().append("<span class='error-text'>Please enter your state.</span>");
      }
      if(errors.length > 0 && !userPtc){
        $("#gform_next_button_19_8").parent().append("<div class='bottom-error'><span>PLEASE CHECK THE INFORMATION</span></div>");
        $("#gform_next_button_34_11").parent().append("<div class='bottom-error'><span>PLEASE CHECK THE INFORMATION</span></div>");
      }else{
        window['optimizely'] = window['optimizely'] || [];
        window['optimizely'].push({
          type: "event",
          eventName: "personalInfoStepClickThrough",
        });
      }

      break;
    case 'page-few-questions':

      if ( $( '.field-questions input:checked' ).val() === 'None of the Above' && window.location.pathname.includes('colocation') != true) {

        if($( '.field-questions').eq(1).find('input:checked').val() === 'None of the Above' || $( '.field-questions').eq(1).find('input:checked').eq(0).val() === 'Bridge, Crown' && $( '.field-questions').eq(1).find('input:checked').eq(1).val() == undefined){
          errors.push( 'Sorry we’re unable to schedule a consultation online based on your current selections. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.' );
          $("#gform_next_button_19_11").parent().append('<div class="bottom-error"><span>Sorry we’re unable to schedule a consultation online based on your current selections. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.</span></div>');
          $("#gform_next_button_34_8").parent().append('<div class="bottom-error"><span>Sorry we’re unable to schedule a consultation online based on your current selections. Please call us at <a href=tel:'+ callCenterNumber +'>'+ formatPhoneNumber(callCenterNumber) +'</a> for assistance.</span></div>');
          $(".gfield_label").first().parent().addClass('gfield_error');
          $(".page-few-questions .gfield_label").first().parent().addClass('gfield_error');
          $(".gfield_label").eq(1).parent().addClass('gfield_error');

          if (window.location.pathname.includes('/schedule-now')) {
            $(".gfield_label").first().parent().addClass('gfield_now_error');
            $(".gfield_label").first().parent().attr('style',"margin-bottom: 1em !important");
            $(".gfield_label").eq(1).parent().addClass('gfield_now_error');
          }
        }

      }

      var checkCountSetOne = 0;
      var checkCountSetTwo = 0;
      var checkCountSetThree = 0;
      $( '.field-questions input' ).each(
        function(index,fieldInfo){
          if($( '.field-questions input' ).eq(index).is(":checked")){
            if(index >= 0 && index <= 3){
              checkCountSetOne++;
            }
            if(index > 3 && index <= 7){
              checkCountSetTwo++;
            }
            if(index > 7 && index <= 15){
              checkCountSetThree++;
            }
          }
      });

      if((checkCountSetOne==0 || checkCountSetTwo ==0 || (checkCountSetThree == 0 && !global.removeThirdQuestion)) && errors.length == 0){
        $("#gform_next_button_19_11").parent().append("<div class='bottom-error'><span>Please complete all the required fields above to continue</span></div>");
        $("#gform_next_button_34_8").parent().append("<div class='bottom-error'><span>Please complete all the required fields above to continue</span></div>");
        errors.push( 'Please select at least one option' );
      }
      if(checkCountSetOne ==0){
        $(".gfield_label").eq(0).parent().addClass('gfield_error');
        $(".page-few-questions .gfield_label").eq(0).parent().addClass('gfield_error');
        // RESKINNING FOR SCHEDULE NOW
        if (window.location.pathname.includes('/schedule-now')) {
          $(".gfield_label").eq(0).parent().addClass('gfield_now_error');
          $(".gfield_contains_required").eq(0).parent().addClass('gfield_now_required_error');
          const dentalConditionField = document.getElementById("field_19_9");
          if (dentalConditionField) {
            dentalConditionField.removeAttribute('id');
            dentalConditionField.id = "field_19_9_error";
          }
        }
      }
      if(checkCountSetTwo ==0){
        $(".gfield_label").eq(1).parent().addClass('gfield_error');
        $(".page-few-questions .gfield_label").eq(1).parent().addClass('gfield_error');
        // RESKINNING FOR SCHEDULE NOW
        if (window.location.pathname.includes('/schedule-now')) {
          $(".gfield_label").eq(1).parent().addClass('gfield_now_error');
          let currentSolutionsField = document.getElementById('field_19_30');
          currentSolutionsField.classList.add("gfield_error_now_override");
          currentSolutionsField.classList.remove("gfield_error");
          $("#field_19_40").eq(1).parent().css({'margin-top': '32px !important'});
          $(".gfield_contains_required").eq(1).parent().addClass('gfield_now_required_error');
        }
      }
      if(checkCountSetThree ==0){
        $(".gfield_label").eq(3).parent().addClass('gfield_error');
        // RESKINNING FOR SCHEDULE NOW
        if (window.location.pathname.includes('/schedule-now')) {
          $(".gfield_label").eq(3).parent().addClass('gfield_now_error');
          $(".gfield_contains_required").eq(3).parent().addClass('gfield_now_required_error');
          const desiredOutcomesField = document.getElementById("field_19_33");
          if (desiredOutcomesField) {
            desiredOutcomesField.removeAttribute('id');
            desiredOutcomesField.id = "field_19_33_error";
          }
        }
      }

      if($(".number-of-years input").val() != '' && $(".number-of-years input").val() <= 0){
        errors.push( 'Please enter a number 1 or greater' );
        $(".gfield_label").eq(2).parent().addClass('gfield_error');
        $(".page-few-questions .gfield_label").eq(2).parent().addClass('gfield_error');
        $("#gform_next_button_19_11").parent().append("<div class='bottom-error'><span>Please enter a value 1 or greater</span></div>");
        $("#gform_next_button_34_8").parent().append("<div class='bottom-error'><span>Please enter a value 1 or greater</span></div>");
        // RESKINNING FOR SCHEDULE NOW
        if (window.location.pathname.includes('/schedule-now')) {
          $(".gfield_label").eq(2).parent().addClass('gfield_now_error');
          $(".gfield_contains_required").eq(2).parent().addClass('gfield_now_required_error');
          $(".number-of-years").css({"padding": "20px 0px 40px !important"})
        }
      }
      if(errors.length == 0){
        window['optimizely'] = window['optimizely'] || [];
        window['optimizely'].push({
          type: "event",
          eventName: "fewQuestionsStepClickThrough",
        });

      }
      //use to input content when page loaded
      break;

    case 'page-datetime-confirm':
      $(".date-time-errors").hide();
      $( '.page-datetime-confirm' ).find( 'input' ).removeClass( 'error' );
      //var testTextConfirmationPopUp = Cookie.get('testTextConfirmationPopUp');
      //var testTextConfirmationCheckBox = Cookie.get('testTextConfirmationCheckBox');
      //if(!testTextConfirmationCheckBox && !testTextConfirmationPopUp){
        if ( ! $( '.field-agree-to-terms input' ).prop( 'checked' ) ){
          errors.push( 'To continue, you must agree to the terms and conditions.' );
          $( '.field-agree-to-terms input' ).addClass( 'error' );
          $(".no-appts-number").prepend("<div class='date-time-errors agree-to-terms-error' style='text-align: center;'>To continue, you must agree to the terms and conditions.</div>");
        }
      //}




      var selectedTimeSlot = $("#choose-date--timelist .choose-date-timelist ul > li.selected").data('timeslot');
      if(selectedTimeSlot == undefined || selectedTimeSlot == ""){
        errors.push( 'Please select a date and time.' );
      }

      // error checking for when birthdate is required
      // if ($( '#patient-birth-date' ).val() === ''){
      //   errors.push( 'Please enter a proper date of birth.' );
      // } else if(!checkFor18AndOlder()){
      //   errors.push( 'Must be 18 or older to submit form.' );
      // }



      break;

    case 'page-datetime':
      if ( ! ccUserInfo.appointment ) {
        errors.push( 'Please select a date and time' );
      }

      break;
    case 'call-me-now':
      if ( $( '#call-me-now-firstName' ).val() === '' ) {
        errors.push(' Please enter your first name.' );
      }

      if ( $( '#call-me-now-lastName' ).val() === '' ) {
        errors.push(' Please enter your last name.' );
      }

      if ( $( '#call-me-now-phone' ).val() === '' ) {
        errors.push(' Please enter your phone.' );
      }

      break;

    case 'page-call-me-later-submit':
      if ( ! $( '.field-agree-to-terms input' ).prop( 'checked' ) ) {
        errors.push( 'To continue, you must agree to the terms and conditions.' );
        $( '.field-agree-to-terms input' ).addClass( 'error' );
      }

      if ( global.ccUserInfo.appointment === null ) {
        errors.push( 'Please select a Date / Time.' );
      }
      break;

    default:

  }
  return errors;
}

export function checkSectionForComplete(section) {
  var errors = [];
  let callCenterNumber = $(".trans_id").first().text() || '888-651-9950';

  switch(section){
    case 'page-gather-info':
      if ( $( '.field-firstName input' ).val() === '' ) {
        return false;
      }

      if ( $('.field-lastName input' ).val() === '' ) {
        return false;
      }

      var phonenumberVal = $( '.field-phone input' ).val();
      var phonenumber = phonenumberVal.replace(/\D/g,'');

      if ( phonenumber === '' ) {
        return false;
      }

      if ( phonenumber.length !== 10 ) {
        return false;
      }

      if ( phonenumber != undefined && phonenumber.length == 10 && (phonenumber[0] == "1" || phonenumber[0] == "0")) {
        return false;
      }

      var emailVal = $( '.field-email input' ).val();
      if ( emailVal === '' ) {
        return false;
      } else {

        var patt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if( ! patt.test( emailVal.trim() ) ) {
          return false;
        }
      }

      if ( $( '.field-address input' ).val() === '' ) {
        return false;
      }

      if ( $( '.field-zipcode input' ).val() === '' ) {
        return false;
      } else if ( $( '.field-zipcode input' ).hasClass('zipcode-error') ) {
        return false;
      }

      if ( $( '.field-city input' ).val() === '' ) {
        return false;
      }

      if ( $( '.field-state select' ).val() === '') {
        return false;
      }

      if($( '.field-birth-date-entry input' ).val() != '' && $( '.field-birth-date-entry input' ).val() != undefined){
        if(!verifyDateExists()){
          return false;
        }

        if(checkForUpperBound()){
          return false;
        }
      }else{
        return false;
      }

      break;
    case 'page-few-questions':

      if ( $( '.field-questions input:checked' ).val() === 'None of the Above' && window.location.pathname.includes('colocation') != true) {
        return false;
      }

      var checkCountSetOne = 0;
      var checkCountSetTwo = 0;
      var checkCountSetThree = 0;
      $( '.field-questions input' ).each(
        function(index,fieldInfo){
          if($( '.field-questions input' ).eq(index).is(":checked")){
            if(index >= 0 && index <= 3){
              checkCountSetOne++;
            }
            if(index > 3 && index <= 7){
              checkCountSetTwo++;
            }
            if(index > 7 && index <= 15){
              checkCountSetThree++;
            }
          }
      });

      if(checkCountSetOne==0 || checkCountSetTwo == 0 || (checkCountSetThree == 0 && !global.removeThirdQuestion)){
        return false;
      }

      break;

    default:

  }
  return true;
}


function checkFor18AndOlder(){
  var birthDate = $( '.field-birth-date-entry input' ).val();
  var dsplit = birthDate.split("/");
  var dateRegex = new RegExp("[0-9]{2}/[0-9]{2}/[0-9]{4}");
  var regexTest = birthDate.match(dateRegex);
  if(regexTest == null){
    return false;
  }
  var checkDate = new Date(parseInt(dsplit[2])+18,dsplit[1]-1,dsplit[0]);
  var dateNow = new Date();
  if(checkDate <= dateNow){
    return true;
  }else{
    return false;
  }
}


function checkForUpperBound(){
  var birthDate = $( '.field-birth-date-entry input' ).val();
  var dsplit = birthDate.split("/");
  var checkDate = new Date(parseInt(dsplit[2]),parseInt(dsplit[0])-1,parseInt(dsplit[1]));
  const upperLimitDate = new Date('Jan 01 1900 00:00:00 GMT-0700 (Mountain Daylight Time)');
  if(checkDate < upperLimitDate){
    return true;
  }else{
    return false;
  }
}

function verifyDateExists(){
  var birthDate = $( '.field-birth-date-entry input' ).val();
  return moment(birthDate, 'MM/DD/YYYY',true).isValid();
}

function createYelpEventId(){
  //Code for creating uuid from:
  //https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

export function createUuid(){
  //Code for creating uuid from:
  //https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

//Submit w1 form (step3)
export function submitFormW1( formID ) {


  // todo: needs to come from callMeNowInit() : form.js
  let callCenterNumber = $(".trans_id").first().text() || '888-651-9950';

  var pageName = $( '.gform_page' ).last().attr( 'class' ).split( ' ' )[ 1 ];

  var newOneMonthSched = Cookie.get("newOneMonthSched");
  var errors;
  errors = validateSectionInlineMessaging(pageName);

  // var submitButton = $( '#gform_submit_button_' + formID );
  var submitButton = $( '#temp_submit_button' );
  var $messageBox = $( '#webform-component-messages' );

  if (typeof global.ccUserInfo.attemptsAtSubmission === 'undefined') {
    ccUserInfo.attemptsAtSubmission = 0;
  }

  ccUserInfo.attemptsAtSubmission++;
    if (typeof ga !== 'undefined') {
      ga(function () {
        var tracker = ga.getAll()[0];
        global.ga.trackingId = tracker.get('trackingId');
        global.ga.clientId = tracker.get('clientId');
        global.ga.userId = Cookie.get('userId');
        global.ga.gclId = Cookie.get('cc-gclid');
      });
    } else {
      if(global.ga != undefined){
        global.ga.trackingId = global.ga.clientId = global.ga.userId = global.ga.gclId = '';
      }
    }


  if ( !errors || errors.length === 0 ) {
    submitButton.slideUp();

    showWaitingIcon( 'Validating Your Appointment' );

    if(ccUserInfo.guid.indexOf("s:") >= 0){
      var guidRegex = new RegExp("([a-z]|[A-Z]|[0-9]){18}");
      var formattedGuidArray = ccUserInfo.guid.match(guidRegex);
      ccUserInfo.guid = formattedGuidArray[0];
    }
    var selectedTimeBlockElement = $("#choose-date--timelist .choose-date-timelist ul > li.selected");

    ccUserInfo.appointmentHtmlInfo = "class = " + selectedTimeBlockElement.attr("class") + " \ntimeslot = " + selectedTimeBlockElement.data('timeslot') +
      "\ndatestring = " + selectedTimeBlockElement.data('datestring') + "\ntimestring = " + selectedTimeBlockElement.data('timestring') +
      "calendarstart = " + selectedTimeBlockElement.data('calendarstart') + "\ncalendarend = " + selectedTimeBlockElement.data('calendarend');

    var dateOfBirth = $( '#patient-birth-date' ).val();
    var dateOfBirth2Month = $( '#patient-birth-date-2-month' ).val();
    var birthDate;
    dateOfBirth ? birthDate = dateOfBirth : birthDate = dateOfBirth2Month;

    if(global.ccUserInfo.birthDate != undefined && global.ccUserInfo.birthDate != ""){
      birthDate = global.ccUserInfo.birthDate;
    }



    var leadData = {
      action: 'cc_salesforce_validate_appointment',
      GUID: ccUserInfo.guid,
      ContactID: ccUserInfo.contactID,	//$contactIDField.val(),
      AccountID: ccUserInfo.accountID,//$accountIDField.val(),
      email:	ccUserInfo.email,
      IsRescheduling: ccUserInfo.reschedulingAppointment,
      AppointmentId: ccUserInfo.appointment,	//$("#edit-submitted-step-choose-date-select-a-time").val(),
      CenterId: ccUserInfo.centerID,	//$centerIDField.val(),
      FewQuestions: ccUserInfo.fewQuestions,
      FirstName: ccUserInfo.firstName,	//$firstNameField.val(),
      LastName: ccUserInfo.lastName,	//$lastNameField.val(),
      Phone: ccUserInfo.phone,	//helpers.format_phonenumber( $phonenumberfield.val(), null, 'US' ),
      Address: ccUserInfo.address,	//$streetAddressField.val() + " " + addressApt,
      City: ccUserInfo.city,	//$cityField.val(),
      State: ccUserInfo.state,	//$stateField.val(),
      Zip: ccUserInfo.zipcode,	//($zipcodeField.val() || $initialZipCodeField.val() )
      testing: window.ccUserInfo.testing,	//($zipcodeField.val() || $initialZipCodeField.val() )
      // securityToken : helpers.getCookie('securityToken'),
      securityToken: global.securityToken,
      leadSegment: ccUserInfo.leadSegment,
      leadSegment5: ccUserInfo.leadSegment5,
      appointmentHtmlInfo: ccUserInfo.appointmentHtmlInfo,
      model2PilotCenter: global.ccUserInfo.model2PilotCenter,
      birthDate: birthDate,
      appointmentTimestring: global.ccUserInfo.appointmentDateTimeStringStart,
    };
    if(global.ga != 'undefined' && window.ga != undefined){
      leadData.GACLIENTID = global.ga.clientId;
      leadData.GAUSERID = global.ga.userId;
      leadData.GATRACKID = global.ga.trackingId;
      leadData.GCLID = global.ga.gclId;
    }
      leadData.page = ccUserInfo.page;
      leadData.trans_id = ccUserInfo.trans_id;
      leadData.colocation_center_c = ccUserInfo.colocation_center_c;

    let parsed = helpers.getAllQueryParams();
    if(parsed.model2testing !== undefined){
      leadData.model2testing = true;
    }

    var testLeadSegment = parsed.testLeadSegment;
    if(testLeadSegment !== undefined){
      leadData.testLeadSegment = testLeadSegment;
    }

    jQuery.ajax( {
      url: ajaxurl,
      data: leadData,
      cache: false,
      dataType: "json",
      type: "POST",
      success: function( result, success ) {
        //text agree update
        /*
        if($("#agree-to-text").prop("checked") == true){
          global.ccUserInfo.textConsent = true;
          global.dataLayer.push({ 'event': 'textConsentedCheckBox' });
          sendUserTextConsent();
        }
        if($("#agree-to-text").prop("checked") != true){
          global.ccUserInfo.textConsent = false;
          global.dataLayer.push({ 'event': 'textNotConsentedCheckBox' });
          sendUserTextConsent();
        }
        */
        if ( result.success === true ) {
          if(global.ccUserInfo.isGreenYellow){
            sendFacebookEvent('GY Online Schedule');
          }


          if(global.ccUserInfo.leadSegment20 <= 20 && global.ccUserInfo.leadSegment20 >=15){
            sendFacebookEvent('Tier1WebSchedule');
          }else if(global.ccUserInfo.leadSegment20 <= 14 && global.ccUserInfo.leadSegment20 >= 8){
            sendFacebookEvent('T2ScheduleComplete');
          }else{
            sendFacebookEvent('T3ScheduleComplete');
          }


          sendFacebookEvent('CompleteRegistration');
          if(result.ghostSchedule == true){
            global.ccUserInfo.ghostInspectorSuccess = true;
          }
          window[ 'gf_submitting_' + formID ] = true;
          $( '.schedule-consultation-form' )[0].submit();

          hideWaitingIcon();
          if(birthDate != "" &&  birthDate != undefined){
            generateMendForms(result.processedAppointmentId);
          }
          var yelpID = createYelpEventId();
          //add to data layer for yelp conversion
          global.dataLayer.push({'event':'yelpConversion',
          'yelpUID':yelpID});
          submitFormUpdated();

        } else if ( result.message === 'No appts' ) {
          //console.log( 'Appointment was taken:' );
          if(newOneMonthSched){
            $(".no-appts-number").prepend("<div class='date-time-errors'>Sorry this appointment was just taken by someone else. Please try a different time or day.</div>")
          }else{
            $messageBox.find( '.message-text' ).html( 'Sorry, that appointment time was just taken by someone else.<br/> Please try a different time, or call<br/> ' + callCenterNumber + '<br/> for assistance.' );
            showMessageBox();
          }
          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Appointment was taken" });
          global.dataLayer.push({ "event": "ajax-error" });

          global.dataLayer.push({ 'appointmentnotavailable': true });
          global.dataLayer.push({ 'event': 'Appointment Not Available' });

          submitButton.slideDown();
          hideWaitingIcon();
        } else {
          //console.log( 'Server Error validating appointment:' );
          if(newOneMonthSched){
            $(".no-appts-number").prepend("<div class='date-time-errors'>Sorry, there was a problem scheduling your appointment. Try again later, or call  " + callCenterNumber + " for assistance.</div>");
          }else{
            $messageBox.find( '.message-text' ).html( 'Sorry, there was a problem scheduling your appointment. Try again later, or call<br/> ' + callCenterNumber + '<br/> for assistance.' );
          }

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Server Error validating appointment" });
          global.dataLayer.push({ "event": "ajax-error" });

          showMessageBox();
          submitButton.slideDown();
          hideWaitingIcon();
        }

      },
      error: function( result, error ) {

        if(newOneMonthSched){
          $(".no-appts-number").prepend("<div class='date-time-errors agree-to-terms-error'>Sorry, there was a problem scheduling your appointment. Try again later, or call<br/> " + callCenterNumber + "<br/> for assistance.</div>");
        }else{
          $messageBox.find( '.message-text' ).html( 'Sorry, there was a problem scheduling your appointment. Try again later, or call<br/> ' + callCenterNumber + '<br/> for assistance.' );
          showMessageBox();
        }

        submitButton.slideDown();
        //console.log( 'Ajax Error validating appointment.', 'Result: ', result, 'Error: ', error );
        hideWaitingIcon();

        global.dataLayer.push({ "error_data": result });
        global.dataLayer.push({ "error_message": "Ajax Error validating appointment." });
        global.dataLayer.push({ "event": "ajax-error" });
      },
    } );
  } else {
    if(!newOneMonthSched){
      var errorHTML = '<h4>Please fix the following:</h4>';
      for ( var i = 0; i < errors.length; i++ ) {
        errorHTML += '<p>' + errors[i] + '</p>';
      }
      $messageBox.find( '.message-text' ).html( errorHTML );
      showMessageBox();
    }
  }
  return false;
}

function generateMendForms(appointmentId){
  var mendFormsData = {};
  mendFormsData.consultInventoryId = appointmentId;
  mendFormsData.securityToken = global.securityToken;
  mendFormsData.action = "cc_salesforce_create_mend_forms";

  jQuery.ajax({
    url: ajaxurl,
    data: mendFormsData,
    cache: false,
    dataType: "json",
    type: "POST",
    // success: function( result, success ) {
    //   //console.log( 'Success: ', result );
    // },
    // error: function( result, error ) {
    //   //console.log( 'Error: ', result );
    // }
  });
}

  function  submitFormUpdated(){
    //create event to replace gravity forms confirmation loaded event
    var evt = document.createEvent("Event");
    evt.initEvent("formHasSubmitted",true,true);
    document.dispatchEvent(evt);
  }


export function schedulingDisabledPopup($under18message = false) {
  hideWaitingIcon();
  $('.form-page-step').css('display',"none");
  var schedDisabledCallCenterNumber, schedDisabledCallCenterNumberFormatted;
  if(window.location.pathname.includes('schedule-now')){
    schedDisabledCallCenterNumber = '8884863443';
    schedDisabledCallCenterNumberFormatted = '888-486-3443';
  }else{
    schedDisabledCallCenterNumber = '8889788625';
    schedDisabledCallCenterNumberFormatted = '888-978-8625';
  }




  //set phone number to phone number for disabled scheduling
  $('.mobile-number a').html(schedDisabledCallCenterNumberFormatted);
  $('.mobile-number a').attr('href','tel:'+schedDisabledCallCenterNumber);
  $('#nav-sticky-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#nav-sticky-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
  //set to be persistent
  Cookie.set('campaign_phone_number', schedDisabledCallCenterNumber);

  var message = '<div id="sched-disabled-messaging">\
  <div id="sched-disabled-header"><h4>Please Call Us</h4></div>\
  <div id="sched-disabled-content">To secure the most convenient day and time possible for your consultation, please give us a call at\
  <a id="sched-disabled-phone" href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a>.\
  We will save the information you provided here to assist you. Thank you.</div>\
  </div>';

  var newMessage = '<div id="sched-disabled-messaging">\
    <div id="sched-disabled-content">Sorry we are not available to schedule a consultation online based on your current selections. Please call us for assistance.\
    <div class="ptc-blue-button"><a href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a></div></div>\
  </div>';

  if($under18message){
    message = '<div id="sched-disabled-messaging">\
  <div id="sched-disabled-header"><h4>Please Call Us</h4></div>\
  <div id="sched-disabled-content">We\'re sorry, but we cannot currently schedule patients under 18 online. To secure the most convenient day and time possible for your consultation, please give us a call at\
  <a id="sched-disabled-phone" href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a>.\
  We will save the information you provided here to assist you. Thank you.</div>\
  </div>';
  }

  var newOneMonthSched = Cookie.get("newOneMonthSched");
  if(newOneMonthSched){
    $('.page-datetime-confirm').html(newMessage);
  }else{
    $('.page-datetime-confirm').html(message);
  }

  $('.gform_body').attr('id','sched-disabled-window');
  global.dataLayer.push( { 'event': 'Schedule Disabled' } );
  global.dataLayer.push( { 'event': 'Schedule Disabled Immediately' } );
}


export function schedulingDisabledExistingAppt() {
  hideWaitingIcon();
  $('.form-page-step').css('display',"none");
  var schedDisabledCallCenterNumber, schedDisabledCallCenterNumberFormatted;
  if(window.location.pathname.includes('schedule-now')){
    schedDisabledCallCenterNumber = '8884863443';
    schedDisabledCallCenterNumberFormatted = '888-486-3443';
  }else{
    schedDisabledCallCenterNumber = '8889788625';
    schedDisabledCallCenterNumberFormatted = '888-978-8625';
  }




  //set phone number to phone number for disabled scheduling
  $('.mobile-number a').html(schedDisabledCallCenterNumberFormatted);
  $('.mobile-number a').attr('href','tel:'+schedDisabledCallCenterNumber);
  $('#nav-sticky-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#nav-sticky-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
  //set to be persistent
  Cookie.set('campaign_phone_number', schedDisabledCallCenterNumber);

  var message = '<div id="sched-disabled-messaging">\
  <div id="sched-disabled-header"><h4>It looks like you already have a consultation scheduled.</h4></div>\
  <div id="sched-disabled-content">Want to cancel or reschedule your appointment? Please give us a call at\
  <a id="sched-disabled-phone" href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a>.\
  We will save the information you provided here to assist you. Thank you.</div>\
  </div>';

  var newMessage = '<div id="sched-disabled-messaging">\
    <div id="sched-disabled-content">Sorry we are not available to schedule a consultation online based on your current selections. Please call us for assistance.\
    <div class="ptc-blue-button"><a href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a></div></div>\
  </div>';

  var newOneMonthSched = Cookie.get("newOneMonthSched");
  if(newOneMonthSched){
    $('.page-datetime-confirm').html(newMessage);
  }else{
    $('.page-datetime-confirm').html(message);
  }

  $('.gform_body').attr('id','sched-disabled-window');
  global.dataLayer.push( { 'event': 'Schedule Disabled' } );
  global.dataLayer.push( { 'event': 'Schedule Disabled Immediately' } );
}

export function schedulingDisabledCampaignPopup() {
  hideWaitingIcon();
  $('.form-page-step').css('display',"none");
  let schedDisabledCallCenterNumber = '8883159490';
  let schedDisabledCallCenterNumberFormatted = '(888) 315-9490';

  //set phone number to phone number for disabled scheduling
  $('.mobile-number a').html(schedDisabledCallCenterNumberFormatted);
  $('.mobile-number a').attr('href','tel:'+schedDisabledCallCenterNumber);
  $('#nav-sticky-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#nav-sticky-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
  //set to be persistent
  Cookie.set('campaign_phone_number', schedDisabledCallCenterNumber);

  var message = '<div id="sched-disabled-messaging">\
  <div id="sched-disabled-header"><h4>Please Call Us</h4></div>\
  <div id="sched-disabled-content">To secure the most convenient day and time possible for your consultation, please give us a call at\
  <a id="sched-disabled-phone" href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a>.\
  We will save the information you provided here to assist you. Thank you.</div>\
  </div>';

  var newMessage = '<div id="sched-disabled-messaging" style="font-size:20px">\
  <div id="sched-disabled-header"><h4>Speak to a Patient Advocate</h4></div>\
  <div id="sched-disabled-content">Based on your responses it looks like you may be a candidate for dental implant treatment! Please <a style="text-decoration: underline;" href="tel:'+schedDisabledCallCenterNumber+'">click here</a> to be connected to a Patient Advocate to discuss next steps and schedule your free consultation and CT Scan.</br>\
  <div class="ptc-blue-button"><a href="tel:'+schedDisabledCallCenterNumber+'">'+"Click to be connected"+'</a></div></div></br>\
  <div id="sched-disabled-content">Don’t have time to connect now? Don’t worry, you can reach us at (888) 315-9490. We have saved all your information so we can pick up right where you left off.</div>\
  </div>';

  $('.page-datetime-confirm').html(newMessage);


  $('.gform_body').attr('id','sched-disabled-window');
}

export function promptToCallWithScheduleDisplay(){
  let schedDisabledCallCenterNumber = '8889788625';
  let schedDisabledCallCenterNumberFormatted = '888-978-8625';

  //set phone number to phone number for disabled scheduling
  $('.mobile-number a').html(schedDisabledCallCenterNumberFormatted);
  $('.mobile-number a').attr('href','tel:'+schedDisabledCallCenterNumber);
  $('#nav-sticky-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#nav-sticky-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
  //set to be persistent
  Cookie.set('campaign_phone_number', schedDisabledCallCenterNumber);
  var message = '<p style="width: 80%;margin-left: 10%;margin-right: 10%;text-align: center;">Can\'t find an appointment that works with your schedule? Call us at <a style="color:#1a72b5 !important; text-decoration:none; font-weight:600;" id="calendar-phone-number-ptc" href="tel:'+schedDisabledCallCenterNumberFormatted+'">'+schedDisabledCallCenterNumberFormatted+'</a></p>';
  $('.make-appointment').prepend(message);
  $('#calendar-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#calendar-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
}

function savePromptToCallCampaign(){
  var promptToCallData = {};
  promptToCallData.GUID = global.ccUserInfo.contactID || global.ccUserInfo.guid;
  promptToCallData.isContact = ( global.ccUserInfo.contactID !== "" && global.ccUserInfo.contactID !== null ) ? true : false;
  promptToCallData.TransId = "onlineschedrestrictedpromptcall";
  promptToCallData.action = "cc_salesforce_submit_campaign_member";
  promptToCallData.securityToken = global.securityToken;
  jQuery.ajax({
    url: ajaxurl,
    data: promptToCallData,
    cache: false,
    dataType: "json",
    type: "POST",
    // success: function( result, success ) {
    //   //console.log( 'Success: ', result );
    // },
    // error: function( result, error ) {
    //   //console.log( 'Error: ', result );
    // }
  });
}

export function saveCampaign(campaignName){
  var promptToCallData = {};
  promptToCallData.GUID = global.ccUserInfo.contactID || global.ccUserInfo.guid;
  promptToCallData.isContact = ( global.ccUserInfo.contactID !== "" && global.ccUserInfo.contactID !== null ) ? true : false;
  promptToCallData.TransId = campaignName;
  promptToCallData.securityToken = global.securityToken;
  promptToCallData.action = "cc_salesforce_submit_campaign_member";

  jQuery.ajax({
    url: ajaxurl,
    data: promptToCallData,
    cache: false,
    dataType: "json",
    type: "POST",
    //success: function( result, success ) {
      //console.log( 'Success: ', result );
    //},
    //error: function( result, error ) {
      //console.log( 'Error: ', result );
    //},
  });
}

export function showPromptToCallMessageBox(){
  var promptToCallHtml = "<p class='prompt-to-call-error'>Sorry we’re unable to schedule a consultation online based on your current selections. Please call us at <a href='tel:8889788625'>888-978-8625</a> for assistance.</p>";
  $('#webform-component-messages .message-text').html(promptToCallHtml);

  $('#webform-component-messages .buttons').find("> div").hide();
  var ok = $('<div class="temp">OK</div>').appendTo($('#webform-component-messages .buttons'));

  showMessageBox();

  ok.click(function(){
    hideMessageBox();
  });

  global.dataLayer.push( { 'event': 'Schedule Disabled' } );
  global.dataLayer.push( { 'event': 'Schedule Disabled On Click' } );

  savePromptToCallCampaign();
}

const DOBTest = Cookie.get('DOB-Variant');


export function initDateTimeField(){
  var dateFieldHtml = DOBTest
  ? `<h5 style="font-size: 18px; font-weight: 500;">Can't find an appointment that works with your schedule?<br> Call us at <a href="tel:8886519950">888-651-9950</a> </h5><hr>
      <div id="dob-info-container"><p style="font-size: 14px; font-weight: 400;">Providing your birth date allows you to securely fill out forms online before your appointment. Your birth date will be used as a password to access these forms and make your visit to the center as smooth and safe as possible. </p>
      </div>
      <div id="dob-input-container" style="display: flex; flex-direction: column; width: 100%; justify-content: center; align-items: center; margin: auto;">
      <div style="display: flex; flex-direction: row; align-items: baseline; width: 100%; justify-content: center;">
      <p style="min-width: fit-content; font-weight: 500;" id="patient-birthdate-text">Birth Date:</p>
      <input style="width: 200px" type="text" inputmode="numeric" id="patient-birth-date" name="patient-birth-date" class="left-margin-schedule-sidebar" placeholder="mm/dd/yyyy" max="100">
      </div>
      </div>
      <p style="font-size: 13px; font-weight: 400;">This information is transmitted and stored securely, in accordance with our <a href="/privacy-policy/" target="_blank">Privacy Policy</a>.</p>`
  : '<label for="patient-birth-date" class="left-padding-schedule-sidebar">Birth Date:</br>\
    <span style="font-size: .8em;color: #1d76b3;" class="tooltip left-padding-schedule-sidebar">Why should I provide my birthdate?</span>\
  </label><br><br>\
  <input type="text" inputmode="numeric" id="patient-birth-date" name="patient-birth-date" class="left-margin-schedule-sidebar" placeholder="mm/dd/yyyy" max="100">\
  <label id="agree-to-terms-label">Agree to Terms<span class="gfield_required">*</span></label>';


  $(dateFieldHtml).insertBefore( ".field-agree-to-terms" );
  $('.tooltip').tooltipster({
    theme: 'tooltipster-light',
    contentCloning: true,
    interactive: true,
    content:$('<span> Providing your birthdate allows you to securely fill<br> out forms online before your appointment. Your birthdate<br> will be used as a password to access these forms and<br> make your visit to the center as smooth and safe as possible. This information<br> is transmitted and stored securely, in accordance with<a href="/privacy-policy/" target="_blank"> our Privacy Policy</a>.</span>'),
  });
  $('#patient-birth-date').mask('99/99/9999',{placeholder:"mm/dd/yyyy"});
  saveCampaign('dob_webtest');
}

export function initDateTimeField2Month(){
  // RESKINNING FOR SCHEDULE NOW
  var dateFieldHtml =  window.location.pathname.includes('schedule-now')
  ? '<label for="patient-birth-date" ><p id="patient-birthdate-text" class="patient-birthdate-text-now">Birth Date:</p>\
    <span style="font-size: .8em;color: #1d76b3;" class="tooltip left-padding-schedule-sidebar">Why should I provide my birthdate?</span>\
  </label><br>\
  <input type="text" inputmode="numeric" id="patient-birth-date-2-month" name="patient-birth-date" class="central-margin-schedule-sidebar" placeholder="mm/dd/yyyy">'
  : '<label for="patient-birth-date" ><p id="patient-birthdate-text">Birth Date:</p>\
    <span style="font-size: .8em;color: #1d76b3;" class="tooltip left-padding-schedule-sidebar">Why should I provide my birthdate?</span>\
  </label><br>\
  <input type="text" inputmode="numeric" id="patient-birth-date-2-month" name="patient-birth-date" class="central-margin-schedule-sidebar" placeholder="mm/dd/yyyy">';



  $(dateFieldHtml).insertBefore( ".field-agree-to-terms" );
  $('.tooltip').tooltipster({
    theme: 'tooltipster-light',
    contentCloning: true,
    interactive: true,
    content:$('<span> Providing your birthdate allows you to securely fill<br> out forms online before your appointment. Your birthdate<br> will be used as a password to access these forms and<br> make your visit to the center as smooth and safe as possible. This information<br> is transmitted and stored securely, in accordance with<a href="/privacy-policy/" target="_blank"> our Privacy Policy</a>.</span>'),
  });
  $('#patient-birth-date-2-month').mask('99/99/9999',{placeholder:"mm/dd/yyyy"});
  saveCampaign('dob_webtest');
}

export function initDateTimeFieldPersonalInfoStep(){
  $('.field-birth-date-entry input').mask('99/99/9999',{placeholder:"mm/dd/yyyy"});
  saveCampaign('dob_webtest');
}

export function processAndSaveCookieData(cookieDataString,cookieName){
  var cookieData = {};
  cookieData.action = "processCookieData";
  cookieData.cookieData = cookieDataString;
  cookieData.process = 'e';
  jQuery.ajax({
    url: ajaxurl,
    data: cookieData,
    cache: false,
    dataType: "json",
    type: "POST",
    success: function( result, success ) {
      Cookie.set(cookieName,result);
    },
    error: function( result, error ) {
      console.log( 'Error: ', result );
    },
  });
}

export function readCookieDataAndSetData(cookieName,pageNumber){
  var cookieDataString = Cookie.get(cookieName);
  var cookieData = {};
  cookieData.action = "processCookieData";
  cookieData.cookieData = cookieDataString;
  cookieData.process = 'd';
  jQuery.ajax({
    url: ajaxurl,
    data: cookieData,
    cache: false,
    dataType: "json",
    type: "POST",
    success: function( result, success ) {
      var dataArray = $.parseJSON(result);
      if(pageNumber == 1){
        setFewQuestionsDataFromSavedData(dataArray);
      }
      if(pageNumber == 2){
        setPersonalInfoDataFromSavedData(dataArray);
        if(global.switchedSteps){
          autofillPageGatherInfo();
        }

      }
    },
    error: function( result, error ) {
      console.log( 'Error: ', result );
    },
  });
}

function setFewQuestionsDataFromSavedData(fewQuestionsData){
  $.each(fewQuestionsData,function(index,questionValue){
    if(questionValue == "checked"){
      // $($('.field-questions input[name!="options"]')[index]).attr('checked', true);
      $($('.field-questions input[name!="options"]')[index]).click();
    }else if(questionValue != null){
      $($('.field-questions input[name!="options"]')[index]).val(questionValue);
    }
  });
}

function setPersonalInfoDataFromSavedData(personalInfoData){
  global.ccUserInfo.firstName = personalInfoData.firstName;
  global.ccUserInfo.lastName = personalInfoData.lastName;
  global.ccUserInfo.phone = personalInfoData.phone;
  global.ccUserInfo.email = personalInfoData.email;
  global.ccUserInfo.address = personalInfoData.address;
  global.ccUserInfo.zipcode = personalInfoData.zipcode;
  global.ccUserInfo.city = personalInfoData.city;
  global.ccUserInfo.state = personalInfoData.state;
  global.ccUserInfo.birthDate = personalInfoData.birthDate;
}

function retrievePartialLeadInfoForFullForm(){
  global.retrieveLeadInfoLock = new $.Deferred();
  var leadData = {};
  leadData.FirstName = global.ccUserInfo.firstName;
  leadData.LastName = global.ccUserInfo.lastName;
  leadData.Phone = global.ccUserInfo.phone;
  leadData.Email = global.ccUserInfo.email;
  leadData.Address = global.ccUserInfo.address;
  leadData.Zipcode = global.ccUserInfo.zipcode;
  leadData.City = global.ccUserInfo.city;
  leadData.State = global.ccUserInfo.state;
  leadData.securityToken = global.securityToken;
  leadData.userLeadReserveFrontEndUid = Cookie.get('userFrontEndId');
  leadData.action = "searchForExistingLeadPartialToFullForm";

  jQuery.ajax({
    url: ajaxurl,
    data: leadData,
    cache: false,
    dataType: "json",
    type: "POST",
    success: function( result, success ) {
      if(result.success == true){
        global.ccUserInfo.retrievedLeadId = result.leadId;
        global.ccUserInfo.newLead = result.newLead;
        global.retrieveLeadInfoLock.resolve(true);
      }else{
        global.retrieveLeadInfoLock.resolve(false);
      }
    },
    error: function( result, error ) {
      console.log( 'Error: ', result );
      global.retrieveLeadInfoLock.resolve(false);
    },
  });
}

export function generateUserFrontEndId(){
    //Code for creating uuid from:
    //https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
    var userFrontEndProject = Cookie.get('userFrontEndId');
    if(userFrontEndProject == undefined){
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = (dt + Math.random()*16)%16 | 0;
          dt = Math.floor(dt/16);
          return (c=='x' ? r :(r&0x3|0x8)).toString(16);
      });
      Cookie.set('userFrontEndId',uuid);
    }
}

export function showTextConsentCheckboxTest(){
  $("#choice_19_17_1").parent().parent().parent().hide();

  var confirmationHtml = `<div class="ginput_container ginput_container_checkbox">
    <ul class="gfield_checkbox" id="input_19_17" style="margin:0px !important; padding-top: 0 !important;">
      <li class="agree-to-text" style="text-align: center; margin-left: 7% !important; margin-right: 5%;">
                <input name="input_17.1" type="checkbox" value="Agree to get texts?" id="agree-to-text" tabindex="30" style="height: 30px !important; width: 30px !important;vertical-align:top";" insp-form-input-id="field-agree-to-texts">
                <label for="agree-to-text" id="agree-to-text-label" style="font-weight: 400;text-align:left;margin-top: 1%;"><b>Get appointment reminders on your phone?</b></br> Yes, I consent to receive autodialed and/or pre-recorded calls and text messages for any purpose, including appointment reminders and review requests, from or on behalf of ClearChoice at the number I provided.  I understand that i) my consent is not required as a condition of doing business with or receiving services from ClearChoice, ii) I may revoke this authorization at any time by calling 1-888-651-9950 or replying “STOP” to a text message from ClearChoice, and iii) standard text messaging rates will apply</label>
      </li>
    </ul>
  </div>`;
  $('.field-agree-to-terms').append(confirmationHtml);
  var agreeText = "<p style'text-align:center;'>By confirming your consult, you agree to the <a href='/terms/' target='_blank'>terms and conditions</a> and that the individual is over the age of 18.</p>"
  $('.field-agree-to-terms').append(agreeText);

}

// adding dental condition questions to the dataLayer
export function addDentalQuestionToDataLayer(el){
  var step_num;
  var step_sub;
  var answear;
  if(el.defaultValue != "" && el.defaultValue != " " && el.defaultValue != undefined && el.defaultValue != null){
    answear = el.defaultValue;
  if(el.id.includes("19_9")){
    step_num = 1.1;
    step_sub = "What Is Your Current Dental Condition";
  }else if(el.id.includes("19_30")){
    step_num = 1.2;
    step_sub = "What Dental Solutions Do You Currently Have";
  }else if(el.id.includes("19_33")){
    step_num = 1.3;
    step_sub = "What Do You Hope To Gain From Dental Implants";
  }else if(el.id.includes("19_37")){
    step_sub = "Accessibility";
    step_num = 1.4;
  }
  if(step_num != undefined && step_num != "" && step_num != " " && step_sub != undefined && step_sub != "" && step_sub != " "){
    global.dataLayer.push({"event":"leadform_substeps",
      step_number: step_num,
      step_subtitle: step_sub,
      checkbox_choice: answear,
    });
  }
}
}

export function promptToCallTestMessagingAndHighlight(){
  var promptToCallHtml;
  if(window.location.pathname.includes('schedule-now')){
    promptToCallHtml = "<div class='ptc-error-message'><span style='color:red'>Sorry we’re unable to schedule a consultation online based on your current selections. </span></break>Please call us at <a href='tel:8884863443'>888-486-3443</a> for assistance.</div>";
  }else{
    promptToCallHtml = "<div class='ptc-error-message'><span style='color:red'>Sorry we’re unable to schedule a consultation online based on your current selections. </span></break>Please call us at <a href='tel:8889788625'>888-978-8625</a> for assistance.</div>";
  }
  $("#instruction-header").html(promptToCallHtml);
  $(".promptToCall").css('background','#ebebeb');
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#instruction-header").offset().top-100,
  }, 1000);

  global.dataLayer.push( { 'event': 'Schedule Disabled' } );
  global.dataLayer.push( { 'event': 'Schedule Disabled On Click' } );

  savePromptToCallCampaign();
}

export function setValueTrackParameters(){
  var parsed = queryString.parse(location.search);
  if(parsed.network){
    if(typeof parsed.network == "string" && parsed.network != '' ){
      localStorage.setItem('valueTrackNetwork',parsed.network);
    }
  }
  if(parsed.campaignid){
    if($.isNumeric(parsed.campaignid)){
      localStorage.setItem('valueTrackCampaignId',parsed.campaignid);
    }
  }
  if(parsed.matchtype){
    if(typeof parsed.matchtype == "string" && parsed.matchtype != ''){
      localStorage.setItem('valueTrackMatchType',parsed.matchtype);
    }
  }
}
