import moment from 'moment';
import {is_w1, is_w2} from '../global/functions';
import Cookie from "js-cookie";

function getMonthName(month) {
  const monthArray = new Array(
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
    );
  return monthArray[ ( month - 1 ) ];
}

function setupOneMonthHeaderAndSidebar(){
  // Populate Appointment information with what we have so far
  const selectedCenter = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID];
  global.ccUserInfo.addressString = `<h6>ClearChoice ${selectedCenter.name} Center</h6>`;
  global.ccUserInfo.addressString += `<p>${selectedCenter.address} `;
  global.ccUserInfo.addressString += `${selectedCenter.address2}<br/>`;
  global.ccUserInfo.addressString += `${selectedCenter.city}, ${selectedCenter.state} `;
  global.ccUserInfo.addressString += `${selectedCenter.zipcode}</p>`;
  // const addressHTML = global.ccUserInfo.addressString;
  $( '#confirmation-details .name' ).html( `${global.ccUserInfo.firstName} ${global.ccUserInfo.lastName}`);
  $( '#confirmation-details .date' ).html( '<span>Select a date on the calendar</span>' );
  $( '#confirmation-details .address' ).html( global.ccUserInfo.addressString );

  const DOBTest = Cookie.get('DOB-Variant');

  if( is_w1() ) {
    // Move term agreement and submit field into right column
    $( '.monthly-schedule-form .field-agree-to-terms' ).appendTo( '#webFormCalendar.cc-web-form-calendar-month .right-wrapper' );
    $( '.monthly-schedule-form .page-datetime-confirm .gform_page_footer' ).appendTo( '#webFormCalendar.cc-web-form-calendar-month .right-wrapper' );
    $( '.make-appointment' ).addClass( 'disabled-button' );
    if ( $( '#temp_submit_button' ).length === 0 ) {
      DOBTest ? $( '.month-calendar-right .right-wrapper' ).append( `<div style="display: flex; flex-direction: column; width: 100%; margin: auto;"><input type="button" id="temp_submit_button" insp-form-input-id="submit-button" class="gform_next_button button" value="Done" style="color: white; border-radius: 10px;  max-width: 70%;" tabindex="21" ><p style="text-align:center; justify-content:center; font-size: 14px !important; margin-bottom: -40px;">After pressing "Schedule My Free Consultation", we will hold an hour of the doctor's time for you. A representative from ClearChoice will attempt to contact you closer to your appointment date to confirm this appointment will still work for you. If you are unable to make your appointment, please call us at <span id="cancellation-phone-number">888-651-9950</span> with at least 48 hours notice.</p></div>`)
      : $( '.month-calendar-right .right-wrapper' ).append( '<input type="button" id="temp_submit_button" insp-form-input-id="submit-button" class="gform_next_button button" value="Confirm Consultation" tabindex="21" >');
    }
  }
}

function setupOneMonthNewHeader(){
  // Populate Appointment information with what we have so far
  const selectedCenter = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID];
  var centerName = selectedCenter.name.toUpperCase();
  var headerHTML = `<div class="oneMonthCalendarHeader">
  <h6 class="schedule-header-text">Your ClearChoice location</h6>
  <h6 class="schedule-header-location">CLEARCHOICE ${centerName}</h6>
  <p class="schedule-header-address">${selectedCenter.address} ${selectedCenter.address2}, ${selectedCenter.city}, ${selectedCenter.state}, ${selectedCenter.zipcode}</p>
  </div>`;
  if( $( '.oneMonthCalendarHeader' ).length == 0){
    $( headerHTML ).prependTo( '#webFormCalendar' );
  }
}

export function monthCalendar(ElemId, month, year, weekOffset, dates, endDate, past, showprev) {
  //when displaying a new month remove prompt to call messaging event from dates so that the events can
  //be properly added to the new month
  $("td").removeAttr('style');
  $("td").removeClass( 'promptToCall' );
  $("td").unbind('click','showPromptToCallMessageBox');

  let datesArr = [];

  setupOneMonthHeaderAndSidebar();
  setupOneMonthNewHeader();
  const today = new Date();
  // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  //removing this code because it is always 0 and never anything else
  // if ( typeof( weekOffset ) === 'undefined' || weekOffset === null ) {
  //   weekOffset = 0;
  // }
  if ( typeof( month ) === 'undefined' || month === null ) {
    month = today.getMonth() + 1;
  }
  if ( typeof( year ) === 'undefined' || year === null ) {
    year = today.getFullYear();
  }
  if ( typeof( dates ) === 'undefined' ) {
    datesArr = null;
  } else {
    for ( const key in dates ) {
      datesArr.push( dates[ key ].date );
    }
  }

  if ( typeof( past ) === 'undefined' ) {
    past = true;
  }

  // this is pulling todays date for calendarDate and tempDate
  const calendarDate = new Date( year, ( month - 1 ), 1, 0, 0, 0, 0 );
  const tempDate = new Date( year, ( month - 1), 1, 0, 0, 0, 0 );

  // sets string date to the string "MonthName, Year" ex "April, 2020" and pushes it to dataLayer
  var stringDate = `${getMonthName( month )}, ${year}`;
  global.dataLayer.push({ 'currentMonth': getMonthName( month )});
  // set header for calendar to the date string
  const oH4 = $( `#${ElemId} .choose-date-month h4` );
  const headerDateHtml = `<span class="blue-month-name-calendar">${getMonthName( month )}</span> <hr class="calendar-horizontal-line"></br><span class="year-string-calendar">${year}</span>`;
  oH4.html( headerDateHtml );

  // set data for month and year on calendar
  const oElem = $( `#${ElemId} table` );
  oElem.data( 'month', month );
  oElem.data( 'year', year );

  // clear out classes on date boxes on calendar when a user is clicking next or last month
  const oTd = oElem.find( 'td' );
  oTd.html( '&nbsp;' ).removeClass( 'currentMonth' ).removeClass( 'currentDay' ).removeClass( 'selected' ).removeClass( 'CalActive' );

  // if calendar is loading for the first time add click event for adding selected class to every calendar day element
  if ( oElem.data( 'boundEvents' ) === null || oElem.data( 'boundEvents' ) === undefined ) {
    oTd.bind( 'click', function() {
      //if the day the user clicked on has available times allow them to select it
      if( $( this ).hasClass( 'CalActive' ) ) {
        //if valid selection remove selected class from all other calendar dates and add it to the one that was clicked
        oTd.removeClass( 'selected' );
        $( this ).addClass( 'selected' );
      }
    } );
    oElem.data( 'boundEvents', true );
  }

  //set events for previous button and next button
  const oButtons = $( `#${ElemId} a.btnPrev, #${ElemId} a.btnNext` );
  //first remove any existing events
  oButtons.unbind( 'click' );
  oButtons.bind( 'click', function() {
    //makes numbers less than 10 into form 0# ex: pad(8) = 07
    function pad( n ) {
      if ( n < 10 ) {
        return `0${n}`;
      } else {
        return n;
      }
    }

    //get current month and year from data on calendar html
    let tmpMonth = oElem.data( 'month' );
    let tmpYear = parseInt( oElem.data( 'year' ) );

    // if the user clicks previous button run monthCalendar with current month-1
    if ( $( this ).hasClass( 'btnPrev' ) ) {
      $(".choose-day-timelist .select-time").slideUp();
      tmpMonth--;
      if ( tmpMonth <= 0 ) {
        tmpMonth = 12;
        tmpYear--;
      }
      tmpMonth = pad( tmpMonth );
      monthCalendar( ElemId, tmpMonth, tmpYear, 0, dates, endDate, past, false );

    // if the user clicks next button run monthCalendar with current month+1
    } else {
      $(".choose-day-timelist .select-time").slideUp();
      tmpMonth++;
      if ( tmpMonth >= 13 ) {
        tmpMonth = 1;
        tmpYear++;
      }
      tmpMonth = pad( tmpMonth );
      monthCalendar( ElemId, tmpMonth, tmpYear, 0, dates, endDate, past, true );
    }
  } );



  // sets day to the number for the day of the week (0-6) Sunday - Saturday
  const day = calendarDate.getDay();
  // used to set initial date from (0 = the last day of the previous month) to (-6. = 6 days back into previous month)
  tempDate.setDate( 0 - day );

  let n = 0;
  //while n less than the total amount of calendar slots
  while( n < oTd.length ) {

    tempDate.setDate( tempDate.getDate() + 1 );

    oTd.eq( n ).html( tempDate.getDate() );

    const yearString = tempDate.getFullYear();
    oTd.eq( n ).data( 'year',yearString );

    let monthString = tempDate.getMonth() + 1;
    monthString = `00${monthString}`;
    monthString = monthString.substring( monthString.length - 2, monthString.length );
    oTd.eq( n ).data( 'month', monthString );

    let dateString = tempDate.getDate();
    dateString = `00${dateString}`;
    dateString = dateString.substring( dateString.length - 2, dateString.length );

    // Set <td> 'data-day'
    oTd.eq( n ).data( 'day', dateString );

    // Adjust dateString
    dateString = `${yearString}-${monthString}-${dateString}`;

    // Reset TD classes
    oTd.eq( n ).addClass('currentMonth').removeClass( 'CalFull' );

    // Test Case Specific - slides up the time selector
    oTd.eq( n ).removeClass( 'inactiveMonth' );

    if( is_w1() ) {
        // $('#webFormCalendar .choose-date-timelist').slideUp();
        $(".choose-date-timelist ul").slideUp();
        $(".choose-date-timelist ul .selected").removeClass('selected');
        if(!$(".timelist-next-button").hasClass('disabled')){
          $(".timelist-next-button").addClass('disabled');
        }

    }

    if ( tempDate.getMonth() == calendarDate.getMonth() ) {
      oTd.eq( n ).addClass( 'currentMonth' );

      if ( datesArr === null ) {
        oTd.eq( n ).addClass( 'CalActive' );
      } else if( $.inArray( dateString, datesArr ) >= 0 ) {
        oTd.eq( n ).addClass( 'CalActive' );
      }

      //Add "full" class to full days
      const dateStr = dateString.replace( /-/g, '/');

      if ( typeof dates[ dateStr ] != 'undefined' ) {

        // Checking for same-day scheduling
        if ( tempDate.getDate() === today.getDate() ) {
          let todayFull = true;

          // if ( global.ccUserInfo.marketScore === 'GYIM' || global.ccUserInfo.leadSegment == 4 || global.ccUserInfo.leadSegment == 5) {
            const apps = dates[ dateStr ].appointmentSlots;

            for ( let i = apps.length - 1; i >= 0; i-- ) {
              // Building moment.js string from appt date/time
              const time = apps[i].start;

              const wholeTime = time.replace( ' ', '.0' );
              const thisDay = apps[i].date;
              const finalDate = `${thisDay} ${wholeTime}`;

              // Appointment is more than 2 hours away & has a block (it's available)
              if ( ( moment( finalDate ) - moment( today ) > 7200000 ) && apps[i].blocks.length ) {

                // Set flag to false ( so we don't "block" off today );
                todayFull = false;
              } else {

                // Set block to "empty/scheduled"
                apps[i].blocks = [];
              }
            // }
          }

          // If todayFull is still true ( no appts match "more than 2 hours in the future" AND "still open in SF" )
          if ( todayFull ) {
            oTd.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
          }
        }
        // End Same-Day Schedule adjustments

        // If day is full:
        if ( dates[ dateStr ].full ) {
          oTd.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
        }

        if ( dates[ dateStr ].promptToCall != undefined &&  dates[ dateStr ].promptToCall == true) {
          oTd.eq( n ).addClass('promptToCall');
        }
      }

    } else {
      oTd.eq( n ).addClass( 'inactiveMonth' );
    }


    //this needs to be fixed entirely to check if there are actual dates to show not just full dates or dates
    // people cant access
    if( tempDate.getMonth() == today.getMonth() && tempDate.getDate() == today.getDate() ) {
      oTd.eq( n ).addClass( 'currentDay' );
      $( `#${ElemId} a.btnNext` ).show();
    }

    if( tempDate.getMonth() == endDate.getMonth() && tempDate.getDate() == endDate.getDate() ) {
      oTd.eq( n ).addClass( 'endDay' );

      // Only hide next button if the endDate isn't also in an inactive month row (extra row that will be hidden if it's all "inactiveMonth" class)
      if( ! oTd.eq(n).hasClass( 'inactiveMonth' ) ) {
        $( `#${ElemId} a.btnNext` ).hide();
      }
    }

    n++;
  }

  if((endDate.getMonth()+1 > oElem.data( 'month') && endDate.getFullYear() == oElem.data( 'year')) ||
      (endDate.getMonth()+1 < oElem.data( 'month') && endDate.getFullYear() > oElem.data( 'year'))){
        global.dataLayer.push({'event':'showNextButton'});
  }

  if( $( 'td.currentDay' ).length > 0 ) {
    $( `#${ElemId} a.btnPrev` ).hide();
  } else {
    global.dataLayer.push({'event':'showPrevButton'});
    $( `#${ElemId} a.btnPrev` ).show();
  }

  if(showprev){
    $( `#${ElemId} a.btnPrev` ).show();
  }

  // Hide the last <tr> if all of the dates in the row are ".inactiveMonth"
  oElem.find( 'tr' ).each( function() {
    if ( $( this ).find( 'td.inactiveMonth' ).length === 7 ) {
      $( this ).hide();
    } else {
      $( this ).show();
    }
  } );
}



/**
 * This function calls the view for the calendar to select appointment dates
 *
 * @param  string 	ElemId
 * @param  string 	month
 * @param  string 	year
 * @param  string 	weekOffset
 * @param  string 	dates
 * @param  string 	endDate
 * @param  boolean 	past
 *
 * @return null
 */
export function weekCalendar(ElemId, month, year, weekOffset, dates, endDate, past, showprev) {

  var today = new Date();
  var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

  if(typeof(weekOffset) === "undefined" || weekOffset === null) {
    weekOffset = 0;
  }
  if(typeof(month) === "undefined" || month === null) {
    month = today.getMonth()+1;
  }
  if(typeof(year) === "undefined" || year === null) {
    year = today.getFullYear();
  }
  if(typeof(dates) === "undefined") {
    dates = null;
  }
  if(typeof(past) === "undefined") {
    past = true;
  }

  var calendarDate = new Date(year, (month-1), 1, 0, 0, 0, 0);
  var tempDate = new Date(year, (month-1), 1, 0, 0, 0, 0);
  //var lastDate = new Date(year, month, 0, 0, 0, 0, 0);
  var day = calendarDate.getDay();

  var stringDate = getMonthName(month) + ', ' + year;

  var oElem = $('#'+ElemId+' table');
  var oButtons = $('#'+ElemId+' a.btnPrev, #'+ElemId+' a.btnNext');

  if( weekOffset <= -2 || past === true ) {
    $('#'+ElemId+' a.btnPrev').hide();
  } else {
    $('#'+ElemId+' a.btnPrev').show();
  }

  $('#'+ElemId+' a.btnNext').show();

  var oTd = oElem.find('td');
  oTd.html('&nbsp;').removeClass("currentMonth").removeClass("currentDay").removeClass("selected").removeClass('CalActive');
  var oH4 =$('#'+ElemId+' h4');
  oH4.html(stringDate);

  oElem.data('month', month);
  oElem.data('year', year);

  if(oElem.data('boundEvents') === null || oElem.data('boundEvents') === undefined) {
    oTd.bind('click', function(){
      if($(this).hasClass('CalActive')) {
        oTd.removeClass("selected");
        $(this).addClass('selected');
      }
    });

    oElem.data('boundEvents', true);
  }

  oButtons.unbind('click');
  oButtons.bind('click', function() {
    //var _dates = dates;
    var tmpMonth = oElem.data('month');
    var tmpYear = oElem.data('year');
    if( $(this).hasClass("btnPrev") ) {
      //tmpMonth--;
      weekOffset--;
      if(tmpMonth <= 0){
        tmpMonth = 12;
        tmpYear--;
      }
      weekCalendar(ElemId, tmpMonth, tmpYear, weekOffset, dates, endDate, past, false);
    } else {
      //tmpMonth++;
      weekOffset++;
      if(tmpMonth >= 13){
        tmpMonth = 1;
        tmpYear++;
      }
      weekCalendar(ElemId, tmpMonth, tmpYear, weekOffset, dates, endDate, past, true);
    }
  });


  var n = 0;

  var firstDateArray = dates[0].split("-");
  var firstDateYear = firstDateArray[0];
  var firstDateMonth = monthNames[firstDateArray[1] - 1];
  var firstDateDay = firstDateArray[2];

  var firstDateString = firstDateMonth + " " + firstDateDay + ", " + firstDateYear;

  var firstDateObj = new Date(firstDateString);

  var offset = ( ( ( ( firstDateObj.getDate() + day ) / 7 ) | 0 ) + weekOffset ) * 7;

  tempDate.setDate(0-day + offset );

  while(n < oTd.length){

    tempDate.setDate(tempDate.getDate()+1);

    oTd.eq(n).html("<span>" + monthNames[tempDate.getMonth()] + "</span><br/>" + tempDate.getDate());

    var yearString = tempDate.getFullYear();
    oTd.eq(n).data('year',yearString );
    var monthString = tempDate.getMonth()+1;
    monthString = '00'+monthString;
    monthString = monthString.substring(monthString.length-2,monthString.length);
    oTd.eq(n).data('month', monthString);
    var dateString = tempDate.getDate();
    dateString = '00'+dateString;
    dateString = dateString.substring(dateString.length-2,dateString.length);
    oTd.eq(n).data('day',dateString);
    dateString = yearString+'-'+monthString+'-'+dateString;

    oTd.eq(n).addClass('currentMonth');
    if(!dates) {
      oTd.eq(n).addClass('CalActive');
    } else if($.inArray(dateString, dates) >= 0) {
      oTd.eq(n).addClass('CalActive');
    }

    if(tempDate.getMonth() === today.getMonth() && tempDate.getDate() === today.getDate() ) {
      oTd.eq(n).addClass('currentDay');
    }

    if(tempDate.getMonth() === endDate.getMonth() && tempDate.getDate() === endDate.getDate() ) {
      oTd.eq(n).addClass('endDay');
      $('#'+ElemId+' a.btnNext').hide();
    }
    n++;
  }

  if(showprev){
    $('#'+ElemId+' a.btnPrev').show();
  }
}
