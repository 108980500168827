import { get } from "jquery";

export function getTimeZoneOffsets(TimeZone1, TimeZone2) {
  var Offset1 = 0;
  var Offset2 = 0;

  switch( TimeZone1 ) {
    case 'AST':
      Offset1 = 0;
      break;
    case 'EST':
      Offset1 = 1;
      break;
    case 'CST':
      Offset1 = 2;
      break;
    case 'MST':
      Offset1 = 3;
      break;
    case 'PST':
      Offset1 = 4;
      break;
    case 'AKST':
      Offset1 = 5;
      break;
    case 'HST':
      Offset1 = 6;

    break;
  } switch( TimeZone2 ) {
    case 'AST':
      Offset2 = 0;
      break;
    case 'EST':
      Offset2 = 1;
      break;
    case 'CST':
      Offset2 = 2;
      break;
    case 'MST':
      Offset2 = 3;
      break;
    case 'PST':
      Offset2 = 4;
      break;
    case 'AKST':
      Offset2 = 5;
      break;
    case 'HST':
      Offset2 = 6;
      break;
  }
  return Offset2-Offset1;
}

export function getActualTime(sYear, sMonth, sDate, sTime, SetOffset) {
  if ( typeof( SetOffset ) === 'undefined' ) {
    SetOffset = 0;
  }

  sTime = sTime.split( ':' );
  var Hour = parseInt( sTime[ 0 ], 10 );
  var Minute = parseInt( sTime[ 1 ], 10 );
  // var Offset = sTime[ 2 ].split( ' ' );
  // Offset = parseInt( Offset[ 1 ], 10 ) /-100;

  var myDate = new Date();
  myDate.setFullYear( sYear );
  myDate.setMonth( ( parseInt( sMonth, 10 ) - 1 ), 1 );
  myDate.setDate( sDate );
  //myDate.setHours((Hour+Offset+SetOffset));
  myDate.setHours( ( Hour+SetOffset ) );
  myDate.setMinutes( Minute );
  myDate.setSeconds( 0 );

  return myDate;
}

export function printActualTime(myDate) {
  var theHour = myDate.getHours();
  var theMinute = padString( myDate.getMinutes(), '00', 2 );

  var theAMPM = 'am';
  if( theHour > 11 ) {
    theHour -= 12;
    theAMPM = 'pm';
  }
  if( theHour === 0 ) {
    theHour = 12;
  }
  return theHour + ':' + theMinute + ' ' + theAMPM;
}

export function padString(sValue, sChar, iLength) {
  sValue = sChar + '' + sValue;
  return sValue.substring( sValue.length - iLength, sValue.length );
}

function getIapaTimezoneOffsetFromThreeLetterAbbreviation(timezoneAbbreviation) {
  switch(timezoneAbbreviation){
    case 'PST':
      return 'America/Los_Angeles';
    case 'MST':
      return 'America/Denver';
    case 'CST':
      return 'America/Chicago';
    case 'EST':
      return 'America/New_York';
    case 'AZ':
      return 'America/Anchorage';
  }
}

function getUsersTimeRelativeToCenter(centerTimeZoneAbbrevation){
  var userTimeZone = getIapaTimezoneOffsetFromThreeLetterAbbreviation(centerTimeZoneAbbrevation);
  var userTime = new Date();
  var userTimeWithOffset = new Date(userTime.toLocaleString("en-US", {timeZone: userTimeZone}));
  return userTimeWithOffset;
}

export function checkIfAppointmentIsInFuture(appointmentTime, $centerTimeZoneAbbreviation){
  var userTime = getUsersTimeRelativeToCenter($centerTimeZoneAbbreviation);
  var appointmentDateTime = new Date(appointmentTime);
  if(userTime < appointmentDateTime){
    return true;
  }else{
    return false;
  }
}
