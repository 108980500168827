import {getTimeZoneOffsets, getActualTime, printActualTime} from "./time";
import moment from 'moment';
import {helpers} from '../global/setUserParamsLegacy';
import {promptToCallTestMessagingAndHighlight,addConfirmationDetails, hideMessageBox, submitFormW1, validateSection,showPromptToCallMessageBox,initDateTimeField2Month} from "./formFunctions";
import Cookie from "js-cookie";
import {progressBar} from "../routes/abtesting";

export function w1_step3_2month() {

  var $theForm = $( 'form.schedule-consultation-form' );
  var formID = $theForm.find( 'input[name="gform_submit"]' ).val();
  var $wrapper = $( '.schedule-consultation-form_wrapper' );
  var $messageBox = $( '#webform-component-messages' );
  // the left and right calendars, used for detaching and appending upon date selection
  var $insertCalLeft;
  var $insertCalRight;
  var $insertCalMobile;
  var $counterForTimeClick = 0;
  // wrapper classes for calendar manipulation
  let $calendarWrapper;
  let $calendarTitleDiv;
  // variable to store selected date
  var dateInfo;
  // the whole left and right container
  var $webFormCalendar2 = $( '#webFormCalendar2' );
  var $webFormCalendar3 = $( '#webFormCalendar3' );
  global.ccUserInfo.numberOfPrompts=0;
  // the left and right calendar tables with dates, not the whole container
  const $leftCalendar = $( '#webFormCalendar2 .choose-date-month > div:first-child' );
  const $rightCalendar = $( '#webFormCalendar3 .choose-date-month > div:first-child' );
  // dates for populating the actual calendar date selection
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var dateSelection = new Date();
  var dateSelection2 = new Date();
  var dateSelection3 = new Date();
  // timelist object
  const $selectObject = $( '#choose-date--timelist ul' );
  // name of client to be displayed on top header of step 3
  var $prospectName = global.ccUserInfo.firstName + " " + global.ccUserInfo.lastName;

  /**
   * custom stuff to alter w2 things to make it work as w1
   */
  // show the terms/checkbox on step3 load and remove asterisk
  $('.field-agree-to-terms').show();

  // add new header content to test calendar
  $( "<div class='insert-after row justify-content-center' style='margin-top: -3%;'><h2 id='prospect-name'>This free consultation is for</h2>" + "<h2 id='prospect-name-var'>&nbsp;" + $prospectName + "</h2></div>").insertBefore( "#twoMonthCalendarTemplate" );
  // $( "<div class='row justify-content-center'><p id='instruction-header'>Please select your earliest appointment date and time on the calendar below," + "</br>" + "and click confirm to book your consultation.</p></div>").insertAfter( ".insert-after" );
  $( "<div class='row justify-content-center'><p id='instruction-header'>Please select your earliest appointment date and time on the calendar below," + "</br>" + "and click confirm to book your consultation.</p></div>").insertAfter( ".insert-after" );

  var newThemeWithProgressBar = Cookie.get("newThemeWithProgressBar");
  if(newThemeWithProgressBar){
    $("#prospect-name-var").hide()
    $("#prospect-name").html("Select Your Date and Time");
    $("#instruction-header").html("Schedule the date and time of your free 60-90 minute consultation.");
  }

  $( '.make-appointment .gform_next_button' ).attr("disabled",true);

  // move the time list to the right place
  $("#choose-date--timelist").detach().appendTo("#twoMonthCalendarTemplate");


  // change the bootstrap classes to have the calendar a little bigger than the right column
  $('.make-appointment > .row > .col-md-6').each(function (i,v){
    $(this).removeClass('col-md-6');

    if( i === 0 && $('#webFormCalendar2').length == 0){
      $(this).addClass('col-md-8');
    }
    else if($('#webFormCalendar2').length == 0){
      $(this).addClass('col-md-4');
    } else{
      $(this).addClass('col-md-6');
    }
  });

  // move the agreement, and submit btn into the right column
  $wrapper.find('.field-agree-to-terms').detach().appendTo('.right-wrapper');

  //moved init date time to personal info step
  // $.when(global.saveLeadInfoLock).then(function(success){
    // initDateTimeField2Month();
  // });


  $wrapper.on( 'click', '#temp_submit_button', function() {
    submitFormW1( formID );
  } );


  $messageBox.on( 'click', '.dismiss, .bg-overlay', function() {
    hideMessageBox();
  } );


  //determines scroll on step 3 for GTM scroll tag
  /*
  $( window ).scroll(function() {
    var h = document.documentElement,
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
    var reached25 = false;
    var reached50 = false;
    var reached75 = false;
    var percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
    if(percent > 25 && percent < 50){
      reached25 = true;
    }
    if(percent > 50 && percent < 75){
      reached50 = true;
    }
    if(percent > 75){
      reached75 = true;
    }
    if(reached75 == true){
      reached25 = true;
      reached50 = true;
    }
    if(reached50 == true){
      reached25 = true;
    }
    global.dataLayer.push({'scroll25':reached25});
    global.dataLayer.push({'scroll50':reached50});
    global.dataLayer.push({'scroll75':reached75});
  });
  */

  //dataLayer for clicking on any calendar time
  $wrapper.on( 'click', 'td.CalActive', function() {
    var dateInfo = $( '.selected' ).data();
    var monthInfo = dateInfo.month != undefined ? dateInfo.month : dateInfo.nextMonth;
    global.dataLayer.push({'event':'clickSelectDate',
    'currentlySelectedDate':monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year});
    if(!($($(this)).hasClass('promptToCall'))){
    global.dataLayer.push({'currentlySelectedYear':dateInfo.year});
    global.dataLayer.push({'currentlySelectedMonth':monthInfo});
    global.dataLayer.push({'currentlySelectedDay':dateInfo.day});
    global.dataLayer.push({'currentlySelectedDateNOPTC':monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year});
    } else{
      global.ccUserInfo.numberOfPrompts++;
      global.dataLayer.push({'promptToCallDateSelected':monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year});
      global.dataLayer.push({'centerPTC': global.ccUserInfo.centerID});
      global.dataLayer.push({'timestamp': Date.now()});
      global.dataLayer.push({'LeadSegmen20PTC': global.ccUserInfo.leadSegment20});
      var daysBetweenTimestamp = new Date(monthInfo + "/"  + dateInfo.day + "/" + dateInfo.year) - new Date();
      var daysBetween = Math.ceil(daysBetweenTimestamp / (1000 * 3600 * 24));
      global.dataLayer.push({'Days between Appt date and form visit date': daysBetween});
      global.dataLayer.push({'event': "PTCDateClicked"});
    }
  });

  /*
  var showHighlightedDays = Cookie.get('testHighlightedScheduleDays');
  if((showHighlightedDays && typeof showHighlightedDays != 'undefined')){
  //optimizely custom event for clicking on any highlighted calendar days
  $wrapper.on( 'click', 'td.HighlightedDay', function() {
   window['optimizely'] = window['optimizely'] || [];
   window['optimizely'].push({
     type: "event",
     eventName: "highlighted_day_click",
     tags: {
      version: "v1",
    },
    });
  });
  } else {
  //optimizely custom event for clicking on any non-ighlighted calendar days for comparative v0
  $wrapper.on( 'click', 'td.ComparativeClickClass', function() {
    window['optimizely'] = window['optimizely'] || [];
    window['optimizely'].push({
      type: "event",
      eventName: "highlighted_day_click",
      tags: {
        version: "v0",
      },
     });
   });
  }
  */

  /**
   * this is when they click the first calendar, the left one, initiates collapse, removal of
   * second calendar and display of times
   */

  //When you select a date on the Calendar, display the Times
  $wrapper.on( 'click', '#webFormCalendar2 td.CalActive', function(e) {
    if($(this).hasClass('promptToCall')){
       // new PTC 05/09/23
      promptToCallTestMessagingAndHighlight();
      $("#webFormCalendar3 td.currentMonth.CalActive").removeClass('selected');
      $("#webFormCalendar4 td.currentMonth.CalActive").removeClass('selected');
      return;
    }else{
      $(".ptc-error-message").hide();
    }

    // Test: Monthly Calendar 5/3/16
    if ( ! $webFormCalendar2.hasClass( 'cc-web-form-calendar-month' ) ) {
      $( '#confirmation-details' ).slideUp();
    }

    if( $webFormCalendar2.find( '.selected' ).length > 0 ){
      $("#webFormCalendar3 td.currentMonth.CalActive").removeClass('selected');
      $("#webFormCalendar4 td.currentMonth.CalActive").removeClass('selected');
      dateInfo = $( '#webFormCalendar2 .selected' ).data();

      $selectObject.empty();

      //Display the correct times. Populare the times to the time object created above
      var times = {};
      $.each( ccUserInfo.availableAppointments.seminars, function( index, time ) {
        if ( time.date === dateInfo.year + '-' + dateInfo.month + '-' + dateInfo.day ) {

          var offset = getTimeZoneOffsets( ccUserInfo.availableAppointments.timezone, ccUserInfo.availableAppointments.timezone );

          var myDate = getActualTime( dateInfo.year, dateInfo.month, dateInfo.day, time.start, offset );

          var myDateText = printActualTime( myDate );

          var filledClass = '';
          if ( time.blocks.length === 0 ) {
            filledClass = ' class="slot-filled" ';
          }

          $selectObject.append(
            $( '<li' + filledClass + '></li>' )
              .attr( {
                "data-timeslot": time.blocks.join( '.' ),
                "data-timestring": moment(myDate).format( 'dddd, MMMM DD, YYYY<br/> h:mma' ),
                "data-calendarstart": moment(myDate).format( 'YYYY-MM-DD HH:mm:ss' ),
                "data-calendarend": moment(myDate).add( 1, 'h' ).format( 'YYYY-MM-DD HH:mm:ss' ),
              } )
              .text( myDateText + ' ' + ccUserInfo.availableAppointments.timezone ) );
        }
      } );



      // Test: Monthly Calendar 5/3/16
      if ( $webFormCalendar2.hasClass( 'cc-web-form-calendar-month' ) ) {

        $calendarWrapper = $webFormCalendar2.find( '.choose-date-month .month-wrapper' );
        $calendarTitleDiv = $webFormCalendar2.find( '.choose-date-month > div:first-child' );
        dateSelection.setFullYear( dateInfo.year );
        dateSelection.setMonth( ( parseInt( dateInfo.month, 10 ) -1 ), 1 );
        dateSelection.setDate( dateInfo.day );

        var dateSelectionText = dayNames[dateSelection.getDay()] + ', ' + monthNames[dateSelection.getMonth()] + ' ' + dateSelection.getDate() + ', ' + dateSelection.getFullYear();

        // Set Global to Date Selection (no time yet)
        global.ccUserInfo.appointmentString = dateSelectionText;

        //collapse the calendar when the date is selected
        $calendarTitleDiv.closest( '.choose-date-month' ).addClass( 'collapsed' );
        $( 'div#twoMonthCalendarTemplate ').attr('style', ' border: 0 !important;  width: 100%; box-shadow: none !important; -webkit-box-shadow:0 !important; margin-left: 0; ');
        $( 'div#twoMonthCalendarTemplate ').addClass('collapsed-template');
        $( '#webFormCalendar2 .choose-date-month ').attr('style', ' border: 1px solid #ccc !important; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc;');
        //detach the other calendar, detach used to save object state
        $insertCalRight = $webFormCalendar3.find( '.choose-date-month' ).detach();
        $insertCalMobile = $( ' #webFormCalendar4 ' ).find( '.choose-date-month' ).detach();
        //change the selected calendar to take up the whole container
        $('#cal-left-wrap').removeClass('col-md-6');
        $('#cal-left-wrap').addClass('col-md-12');
        $('#cal-right-wrap').css({"z-index":"-1"});
        $('.mobile-month-content').css({"z-index":"-1"});
        $calendarTitleDiv.find( '.calendar-title-nav' ).slideUp();
        $calendarTitleDiv.find( '.date-selection .js-day-selection' ).html( dateSelectionText );

        // Populate hidden date field
        $webFormCalendar2.find( '.js-choose-day' ).focus().val( dateSelectionText ).attr( 'value', dateSelectionText ).blur();
        //used to dynamically reset the timelist if a user goes back to look at a different date
        //$( '#twoMonthCalendarTemplate .js-change-time' ).click();
        if(($webFormCalendar2.find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        else if(($webFormCalendar3.find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        else if(($("#webFormCalendar4").find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }


        $calendarTitleDiv.find( '.date-selection' ).slideDown();
        $calendarWrapper.slideUp();
        if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
          $( '.make-appointment' ).addClass( 'disabled-button' );
          $( '.make-appointment .gform_next_button' ).attr("disabled",true);
          progressBar(3);
        }
        $selectObject.closest( '#choose-date--timelist' ).slideDown( '300' );
        $selectObject.parent().slideDown( '300' );

        // Animate Scrolldown to Time Selection section
        if( $( window ).width() <= 774 ) {
          setTimeout( function() {
            helpers.scrollPageTo( '.choose-day-timelist', 120 );
          }, 300 );
        }

        $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString + '<br /><span>Select a time</span>' );
        //End Test 5/3/16

      } else {

        $selectObject.parent().slideDown();

        // Animate Scrolldown to Time Date Selection
        if( $( window ).width() <= 774 ) {
          helpers.scrollPageTo( '.choose-day-timelist', 20 );
        }

      }

    } else {
      $selectObject.parent().slideUp();
    }
    ccUserInfo.appointment = null;
  } );


/**
   * this is when they click the second calendar, the right one, initiates collapse, removal of
   * first calendar and display of times
   */

  //When you select a date on the Calendar, display the Times
  $wrapper.on( 'click', '#webFormCalendar3 td.CalActive', function(e) {
    if($(this).hasClass('promptToCall')){
      // new PTC 05/09/23
      promptToCallTestMessagingAndHighlight();
      $("#webFormCalendar2 td.currentMonth.CalActive").removeClass('selected');
      $("#webFormCalendar4 td.currentMonth.CalActive").removeClass('selected');
      return;
    }else{
      $(".ptc-error-message").hide();
    }

    // Test: Monthly Calendar 5/3/16
    if ( ! $webFormCalendar3.hasClass( 'cc-web-form-calendar-month' ) ) {
      $( '#confirmation-details' ).slideUp();
    }

    if( $webFormCalendar3.find( '.selected' ).length > 0 ){
      $("#webFormCalendar2 td.currentMonth.CalActive").removeClass('selected');
      $("#webFormCalendar4 td.currentMonth.CalActive").removeClass('selected');
      dateInfo = $( '#webFormCalendar3 .selected' ).data();

      $selectObject.empty();

      //Display the correct times.
      var times = {};
      $.each( ccUserInfo.availableAppointments.seminars, function( index, time ) {
        if ( time.date === dateInfo.year + '-' + dateInfo.nextMonth + '-' + dateInfo.day ) {

          var offset = getTimeZoneOffsets( ccUserInfo.availableAppointments.timezone, ccUserInfo.availableAppointments.timezone );

          var myDate = getActualTime( dateInfo.year, dateInfo.nextMonth, dateInfo.day, time.start, offset );

          var myDateText = printActualTime( myDate );

          var filledClass = '';
          if ( time.blocks.length === 0 ) {
            filledClass = ' class="slot-filled" ';
          }

          $selectObject.append(
            $( '<li' + filledClass + '></li>' )
              .attr( {
                "data-timeslot": time.blocks.join( '.' ),
                "data-timestring": moment(myDate).format( 'dddd, MMMM DD, YYYY<br/> h:mma' ),
                "data-calendarstart": moment(myDate).format( 'YYYY-MM-DD HH:mm:ss' ),
                "data-calendarend": moment(myDate).add( 1, 'h' ).format( 'YYYY-MM-DD HH:mm:ss' ),
              } )
              .text( myDateText + ' ' + ccUserInfo.availableAppointments.timezone ) );
        }
      } );



      // Test: Monthly Calendar 5/3/16
      if ( $webFormCalendar3.hasClass( 'cc-web-form-calendar-month' ) ) {

        $calendarWrapper = $webFormCalendar3.find( '.choose-date-month .month-wrapper' );
        $calendarTitleDiv = $webFormCalendar3.find( '.choose-date-month > div:first-child' );
        dateSelection2.setFullYear( dateInfo.year );
        dateSelection2.setMonth( ( parseInt( dateInfo.nextMonth, 10 ) -1 ), 1 );
        dateSelection2.setDate( dateInfo.day );
        var dateSelectionText2 = dayNames[dateSelection2.getDay()] + ', ' + monthNames[dateSelection2.getMonth()] + ' ' + dateSelection2.getDate() + ', ' + dateSelection2.getFullYear();
        // Set Global to Date Selection (no time yet)
        global.ccUserInfo.appointmentString = dateSelectionText2;

        //collapse the calendar when the date is selected
        $calendarTitleDiv.closest( '.choose-date-month' ).addClass( 'collapsed' );
        $( 'div#twoMonthCalendarTemplate ').attr('style', ' border: 0 !important;  width: 100%; box-shadow: none !important; -webkit-box-shadow:0 !important; margin-left: 0; ');
        $( 'div#twoMonthCalendarTemplate ').addClass('collapsed-template');
        $( '#webFormCalendar3 .choose-date-month ').attr('style', ' border: 1px solid #ccc !important; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc;');
        //detach the other calendar, detach used to save object state
        $insertCalLeft = $webFormCalendar2.find( '.choose-date-month' ).detach();
        $insertCalMobile = $( ' #webFormCalendar4 ' ).find( '.choose-date-month' ).detach();
        //change the selected calendar to take up the whole container
        $('#cal-right-wrap').removeClass('col-md-6');
        $('#cal-right-wrap').addClass('col-md-12');
        $('#cal-left-wrap').css({"z-index":"-1"});
        $('.mobile-month-content').css({"z-index":"-1"});
        $calendarTitleDiv.find( '.calendar-title-nav' ).slideUp();
        $calendarTitleDiv.find( '.date-selection .js-day-selection' ).html( dateSelectionText2 );

        // Populate hidden date field
        $webFormCalendar3.find( '.js-choose-day' ).focus().val( dateSelectionText2 ).attr( 'value', dateSelectionText2 ).blur();
        //used to dynamically reset the timelist if a user goes back to look at a different date
        //$( '#twoMonthCalendarTemplate .js-change-time' ).click();
        if(($webFormCalendar2.find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        else if(($webFormCalendar3.find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        else if(($("#webFormCalendar4").find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        $calendarTitleDiv.find( '.date-selection' ).slideDown();
        $calendarWrapper.slideUp();
        if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
          $( '.make-appointmente' ).addClass( 'disabled-button' );
          $( '.make-appointment .gform_next_button' ).attr("disabled",true);
          progressBar(3);
        }
        $selectObject.closest( '#choose-date--timelist' ).slideDown( '300' );
        $selectObject.parent().slideDown( '300' );

        // Animate Scrolldown to Time Selection section
        if( $( window ).width() <= 774 ) {
          setTimeout( function() {
            helpers.scrollPageTo( '.choose-day-timelist', 120 );
          }, 300 );
        }

        $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString + '<br /><span>Select a time</span>' );
        //End Test 5/3/16
      } else {

        $selectObject.parent().slideDown();

        // Animate Scrolldown to Time Date Selection
        if( $( window ).width() <= 774 ) {
          helpers.scrollPageTo( '.choose-day-timelist', 20 );
        }

      }

    } else {
      $selectObject.parent().slideUp();
    }
    ccUserInfo.appointment = null;
  } );



  /**
   * this is when they click the mobile calendar, initiates collapse, removal of
   * first/second calendar and display of times
   */

  //When you select a date on the Calendar, display the Times
  $wrapper.on( 'click', '#webFormCalendar4 td.CalActive', function(e) {
    if($(this).hasClass('promptToCall')){
      // new PTC 05/09/23
      promptToCallTestMessagingAndHighlight();
      $("#webFormCalendar2 td.currentMonth.CalActive").removeClass('selected');
      $("#webFormCalendar3 td.currentMonth.CalActive").removeClass('selected');
      return;
    }else{
      $(".ptc-error-message").hide();
    }

    // Test: Monthly Calendar 5/3/16
    if ( ! $("#webFormCalendar4").hasClass( 'cc-web-form-calendar-month' ) ) {
      $( '#confirmation-details' ).slideUp();
    }

    if( $("#webFormCalendar4").find( '.selected' ).length > 0 ){
      $("#webFormCalendar2 td.currentMonth.CalActive").removeClass('selected');
      $("#webFormCalendar3 td.currentMonth.CalActive").removeClass('selected');
      dateInfo = $( '#webFormCalendar4 .selected' ).data();
      $selectObject.empty();

      //Display the correct times.
      var times = {};
      $.each( ccUserInfo.availableAppointments.seminars, function( index, time ) {
        if ( time.date === dateInfo.year + '-' + dateInfo.month + '-' + dateInfo.day ) {

          var offset = getTimeZoneOffsets( ccUserInfo.availableAppointments.timezone, ccUserInfo.availableAppointments.timezone );

          var myDate = getActualTime( dateInfo.year, dateInfo.month, dateInfo.day, time.start, offset );

          var myDateText = printActualTime( myDate );

          var filledClass = '';
          if ( time.blocks.length === 0 ) {
            filledClass = ' class="slot-filled" ';
          }

          $selectObject.append(
            $( '<li' + filledClass + '></li>' )
              .attr( {
                "data-timeslot": time.blocks.join( '.' ),
                "data-timestring": moment(myDate).format( 'dddd, MMMM DD, YYYY<br/> h:mma' ),
                "data-calendarstart": moment(myDate).format( 'YYYY-MM-DD HH:mm:ss' ),
                "data-calendarend": moment(myDate).add( 1, 'h' ).format( 'YYYY-MM-DD HH:mm:ss' ),
              } )
              .text( myDateText + ' ' + ccUserInfo.availableAppointments.timezone ) );
        }
      } );



      // Test: Monthly Calendar 5/3/16
      if ( $("#webFormCalendar4").hasClass( 'cc-web-form-calendar-month' ) ) {
        $calendarWrapper = $("#webFormCalendar4").find( '.choose-date-month .month-wrapper' );
        $calendarTitleDiv = $("#webFormCalendar4").find( '.choose-date-month > div:first-child' );
        dateSelection3.setFullYear( dateInfo.year );
        dateSelection3.setMonth( ( parseInt( dateInfo.month, 10 ) -1 ), 1 );
        dateSelection3.setDate( dateInfo.day );
        var dateSelectionText3 = dayNames[dateSelection3.getDay()] + ', ' + monthNames[dateSelection3.getMonth()] + ' ' + dateSelection3.getDate() + ', ' + dateSelection3.getFullYear();
        // Set Global to Date Selection (no time yet)
        global.ccUserInfo.appointmentString = dateSelectionText3;


        //collapse the calendar when the date is selected
        $calendarTitleDiv.closest( '.choose-date-month' ).addClass( 'collapsed' );
        $insertCalLeft = $webFormCalendar2.find( '.choose-date-month' ).detach();
        $insertCalRight = $webFormCalendar3.find( '.choose-date-month' ).detach();
        $( 'div#twoMonthCalendarTemplate ').attr('style', ' border: 0 !important;  width: 100%; box-shadow: none !important; -webkit-box-shadow:0 !important; margin-left: 0; ');
        $( 'div#twoMonthCalendarTemplate ').addClass('collapsed-template');
        $( '#webFormCalendar4 .choose-date-month ').attr('style', ' border: 1px solid #ccc !important; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc;');
        //detach the other calendar, detach used to save object state

        //change the selected calendar to take up the whole container
        $('.mobile-month-content').removeClass('col-md-6');
        $('.mobile-month-content').addClass('col-md-12');
        $('#cal-right-wrap').css({"z-index":"-1"});
        $calendarTitleDiv.find( '.calendar-title-nav' ).slideUp();
        $calendarTitleDiv.find( '.date-selection .js-day-selection' ).html( dateSelectionText3 );

        // Populate hidden date field
        $('#webFormCalendar4').find( '.js-choose-day' ).focus().val( dateSelectionText3).attr( 'value', dateSelectionText3 ).blur();
        //used to dynamically reset the timelist if a user goes back to look at a different date
        //$( '#twoMonthCalendarTemplate .js-change-time' ).click();
        if(($webFormCalendar2.find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        else if(($webFormCalendar3.find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        else if(($("#webFormCalendar4").find( '.choose-date-month' )).length == 0){
          if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
            $( '.make-appointment' ).addClass( 'disabled-button' );
            $( '.make-appointment .gform_next_button' ).attr("disabled",true);
            progressBar(3);
          }
          $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
          $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
          $( '#choose-date--timelist ul' ).slideDown();
          e.stopPropagation();
        }
        $calendarTitleDiv.find( '.date-selection' ).slideDown();
        $calendarWrapper.slideUp();
        if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
          $( '.make-appointment' ).addClass( 'disabled-button' );
          $( '.make-appointment .gform_next_button' ).attr("disabled",true);
          progressBar(3);
        }
        $selectObject.closest( '#choose-date--timelist' ).slideDown( '300' );
        $selectObject.parent().slideDown( '300' );

        // Animate Scrolldown to Time Selection section
        if( $( window ).width() <= 774 ) {
          setTimeout( function() {
            helpers.scrollPageTo( '.choose-day-timelist', 120 );
          }, 300 );
        }

        $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString + '<br /><span>Select a time</span>' );
        //End Test 5/3/16

      } else {

        $selectObject.parent().slideDown();

        // Animate Scrolldown to Time Date Selection
        if( $( window ).width() <= 774 ) {
          helpers.scrollPageTo( '.choose-day-timelist', 20 );
        }

      }

    } else {
      $selectObject.parent().slideUp();
    }
    ccUserInfo.appointment = null;
  } );



  /**
   * click change (date), belongs to first or left calendar, sends them back to the calendar
   * and appends the second calendar back in to display dates
   */
  // Test: Monthly Calendar 5/3/16
  $wrapper.on( 'click', '#webFormCalendar2 .js-change-day', function( e ) 	{
    //change date dataLayer var
    global.dataLayer.push({'event':'clickChangeDate'});

    //removes the first calendar from being collapsed
    $leftCalendar.closest( '.choose-date-month' ).removeClass( 'collapsed' );
    //appends the second calendar back in
    $webFormCalendar3.append($insertCalRight);
    $(' #webFormCalendar4 ').append($insertCalMobile);
    $leftCalendar.find( '.calendar-title-nav' ).slideDown();
    $rightCalendar.find( '.calendar-title-nav' ).slideDown();
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.calendar-title-nav' ).slideDown();
    $leftCalendar.find( '.date-selection .js-day-selection' ).html( '' );
    $rightCalendar.find( '.date-selection .js-day-selection' ).html( '' );
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.date-selection .js-day-selection' ).html( '' );
    $leftCalendar.find( '.date-selection' ).slideUp();
    $rightCalendar.find( '.date-selection' ).slideUp();
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.date-selection' ).slideUp();
    $( '#webFormCalendar2 .choose-date-month .month-wrapper' ).slideDown();
    $( '#webFormCalendar3 .choose-date-month .month-wrapper' ).slideDown();
    $( '#webFormCalendar4 .choose-date-month .month-wrapper' ).slideDown();
    if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
      $( '.make-appointment' ).addClass( 'disabled-button' );
      $( '.make-appointment .gform_next_button' ).attr("disabled",true);
      progressBar(3);
    }
    $('.choose-date-timelist').removeClass('collapsed')
    //removes the full width container of the selected calendar, changes back to display both calendars
    $('#cal-left-wrap').removeClass('col-md-12');
    $('#cal-left-wrap').addClass('col-md-6');
    $('#cal-right-wrap').css({"z-index":""});
    $('.mobile-month-content').css({"z-index":""});
    $( '#twoMonthCalendarTemplate ').attr('style', ' border: 1px solid #ccc !important;  width: 98%; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc; margin-left: 1%; ');
    $( '#twoMonthCalendarTemplate ').removeClass('collapsed-template');
    $( '#webFormCalendar2 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#webFormCalendar3 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#webFormCalendar4 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#choose-date--timelist ul' ).closest( '#choose-date--timelist' ).slideUp();

    e.stopPropagation();
  } );

  /**
   * click change (date), belongs to second or right calendar, sends them back to the calendar
   * and appends the first calendar back in to display dates
   */
  // Test: Monthly Calendar 5/3/16
  $wrapper.on( 'click', '#webFormCalendar3 .js-change-day', function( e ) 	{
    //change date dataLayer var
    global.dataLayer.push({'event':'clickChangeDate'});
    $rightCalendar.closest( '.choose-date-month' ).removeClass( 'collapsed' );
    $( '#webFormCalendar2' ).append($insertCalLeft);
    $(' #webFormCalendar4 ').append($insertCalMobile);
    $leftCalendar.find( '.calendar-title-nav' ).slideDown();
    $rightCalendar.find( '.calendar-title-nav' ).slideDown();
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.calendar-title-nav' ).slideDown();
    $leftCalendar.find( '.date-selection .js-day-selection' ).html( '' );
    $rightCalendar.find( '.date-selection .js-day-selection' ).html( '' );
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.date-selection .js-day-selection' ).html( '' );
    $leftCalendar.find( '.date-selection' ).slideUp();
    $rightCalendar.find( '.date-selection' ).slideUp();
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.date-selection' ).slideUp();
    $( '#webFormCalendar3 .choose-date-month .month-wrapper' ).slideDown();
    $( '#webFormCalendar2 .choose-date-month .month-wrapper' ).slideDown();
    $( '#webFormCalendar4 .choose-date-month .month-wrapper' ).slideDown();
    if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
      $( '.make-appointment' ).addClass( 'disabled-button' );
      $( '.make-appointment .gform_next_button' ).attr("disabled",true);
      progressBar(3);
    }
    $('.choose-date-timelist').removeClass('collapsed')
    //removes the full width container of the selected calendar, changes back to display both calendars
    $('#cal-right-wrap').removeClass('col-md-12');
    $('#cal-right-wrap').addClass('col-md-6');
    $('#cal-left-wrap').css({"z-index":""});
    $('.mobile-month-content').css({"z-index":""});
    $( '#twoMonthCalendarTemplate ').attr('style', ' border: 1px solid #ccc !important;  width: 98%; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc; margin-left: 1%; ');
    $( '#webFormCalendar2 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#webFormCalendar3 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#webFormCalendar4 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#choose-date--timelist ul' ).closest( '#choose-date--timelist' ).slideUp();

    e.stopPropagation();
  } );

   /**
   * click change (date), belongs to mobile calendar, sends them back to the calendar
   * and appends the first calendar back in to display dates
   */
  // Test: Monthly Calendar 5/3/16
  $wrapper.on( 'click', '#webFormCalendar4 .js-change-day', function( e ) 	{
    //change date dataLayer var
    global.dataLayer.push({'event':'clickChangeDate'});
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).closest( '.choose-date-month' ).removeClass( 'collapsed' );
    $insertCalLeft.appendTo( '#webFormCalendar2' );
    $insertCalRight.appendTo( '#webFormCalendar3');
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.calendar-title-nav' ).slideDown();
    $leftCalendar.find( '.calendar-title-nav' ).slideDown();
    $rightCalendar.find( '.calendar-title-nav' ).slideDown();
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.date-selection .js-day-selection' ).html( '' );
    $leftCalendar.find( '.date-selection .js-day-selection' ).html( '' );
    $rightCalendar.find( '.date-selection .js-day-selection' ).html( '' );
    $( '#webFormCalendar4 .choose-date-month > div:first-child' ).find( '.date-selection' ).slideUp();
    $leftCalendar.find( '.date-selection' ).slideUp();
    $rightCalendar.find( '.date-selection' ).slideUp();
    $( '#webFormCalendar4 .choose-date-month .month-wrapper' ).slideDown();
    $( '#webFormCalendar3 .choose-date-month .month-wrapper' ).slideDown();
    $( '#webFormCalendar2 .choose-date-month .month-wrapper' ).slideDown();

    if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
      $( '.make-appointment' ).addClass( 'disabled-button' );
      $( '.make-appointment .gform_next_button' ).attr("disabled",true);
      progressBar(3);
    }
    $('.choose-date-timelist').removeClass('collapsed')
    //removes the full width container of the selected calendar, changes back to display both calendars
    $('#cal-right-wrap').css({"z-index":""});
    $('.mobile-month-content').css({"z-index":""});
    $( '#twoMonthCalendarTemplate ').attr('style', ' border: 1px solid #ccc !important;  width: 98%; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc; margin-left: 1%; ');
    $( '#webFormCalendar2 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#webFormCalendar3 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#webFormCalendar4 .choose-date-month ').attr('style', ' border: 0; box-shadow: 0; -webkit-box-shadow: 0;');
    $( '#choose-date--timelist ul' ).closest( '#choose-date--timelist' ).slideUp();

    e.stopPropagation();
  } );



  /**
   * click change (for time), sends the back to collapsed first calendar
   */
  $wrapper.on( 'click', '#twoMonthCalendarTemplate .js-change-time', function( e ) {
    //change date dataLayer var
    global.dataLayer.push({'event': 'clickChangeTime'});
    if(($webFormCalendar2.find( '.choose-date-month' )).length == 0){
      if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
        $( '.make-appointment' ).addClass( 'disabled-button' );
        $( '.make-appointment .gform_next_button' ).attr("disabled",true);
        progressBar(3);
      }
      $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
      $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
      $( '#choose-date--timelist ul' ).slideDown();
      e.stopPropagation();
    }
    else if(($webFormCalendar3.find( '.choose-date-month' )).length == 0){
      if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
        $( '.make-appointment' ).addClass( 'disabled-button' );
        $( '.make-appointment .gform_next_button' ).attr("disabled",true);
        progressBar(3);
      }
      $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
      $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
      $( '#choose-date--timelist ul' ).slideDown();
      e.stopPropagation();
    }
    else if(($("#webFormCalendar4").find( '.choose-date-month' )).length == 0){
      if (! $( '.make-appointment' ).hasClass( 'disabled-button' ) ) {
        $( '.make-appointment' ).addClass( 'disabled-button' );
        $( '.make-appointment .gform_next_button' ).attr("disabled",true);
        progressBar(3);
      }
      $( '#choose-date--timelist .choose-date-timelist' ).removeClass( 'collapsed' );
      $( '#choose-date--timelist .choose-date-timelist h3.date-selection' ).slideUp();
      $( '#choose-date--timelist ul' ).slideDown();
      e.stopPropagation();
    }
  });
  //End Test 5/3/16

  /**
   * click any of the time slots logic
   */
  $wrapper.on( 'click', '#choose-date--timelist ul > li', function( e ) {
    var $this = $( this );
    if( ! $this.data( 'timeslot' ) ){
      return;
    }
    $( '#choose-date--timelist ul > li' ).removeClass( 'selected' );
    $this.addClass( 'selected' );

    var timeData = $("#choose-date--timelist ul > li.selected").data();
    var appointmentStartTimeDateObject = new Date(timeData.calendarstart);
    var currentTimeString = appointmentStartTimeDateObject.getHours()+":"+appointmentStartTimeDateObject.getMinutes();
    var amOrpm = timeData.timestring.includes("am") ? "am" : "pm";
    currentTimeString = currentTimeString + " " + amOrpm;

    //add to data layer for selecting time
    global.dataLayer.push({'event':'clickSelectTime',
    'appointmentTime':currentTimeString});

    // Add the user to a "Date/Time Selected" Campaign (only the first time they select a timeslot) - 11/28/2016
    if( ccUserInfo.appointment === '' || ccUserInfo.appointment === null ) {
      var scheduledNotConfirmed = {};
      scheduledNotConfirmed.GUID = ccUserInfo.guid;
      scheduledNotConfirmed.isContact = ( ccUserInfo.contactID !== '' && ccUserInfo.contactID !== null ) ? true : false;
      scheduledNotConfirmed.TransId = 'onlinescheddateandtime';
      scheduledNotConfirmed.action = 'cc_salesforce_submit_campaign_member';
      scheduledNotConfirmed.securityToken = global.securityToken;
      scheduledNotConfirmed.testing = window.ccUserInfo.testing;

      jQuery.ajax( {
        url: ajaxurl,
        data: scheduledNotConfirmed,
        cache: false,
        dataType: 'json',
        type: 'POST',
        success: function( result, success ) {
          //console.log( 'Success: ', result );
        },
        error: function( result, error ) {
          //console.log( 'Error: ', result );
        },
      } );
    }

    global.ccUserInfo.appointment = $this.data( 'timeslot' );
    global.ccUserInfo.appointmentString = $this.data( 'timestring' );
    global.ccUserInfo.calendarTimeString = $this.data( 'calendarstart' );
    global.ccUserInfo.calendarTimeEndString = $this.data( 'calendarend' );
    global.ccUserInfo.appointmentDateTimeStringStart = $this.data( 'calendarstart' );

    $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString );

    addConfirmationDetails( formID );

    // proceed to schedule for calendar 2 or left calendar by pulling data out of the time selection
    if ( $webFormCalendar2.hasClass( 'cc-web-form-calendar-month' ) ) {
        var timeString = $this.html();
        $this.closest( 'ul' ).slideUp();
        $this.closest( '.choose-date-timelist' ).addClass( 'collapsed' );
        $this.closest( '.choose-date-timelist' ).find( 'h3 span.js-time-selection' ).html( timeString );
        $this.closest( '.choose-date-timelist' ).find( 'h3' ).slideDown();
        $( '.make-appointment' ).removeClass( 'disabled-button' );
        $( '.make-appointment .gform_next_button' ).attr("disabled",false);
        progressBar(4);

        // Populate hidden date field
        $webFormCalendar2.find( '.js-choose-time' ).focus().val( timeString ).attr( 'value', timeString ).blur();

        // Animate Scrolldown to Confirmation section
        if( $( window ).width() <= 774 ) {
        // Wait until animation is done
          setTimeout( function() {
            helpers.scrollPageTo( '#confirmation-details h6', 160 );
          }, 300 );
        }

        e.stopPropagation();
    }

     // proceed to schedule for calendar 3 or right calendar by pulling data out of the time selection
     if ( $webFormCalendar3.hasClass( 'cc-web-form-calendar-month' ) ) {
        var timeString2 = $this.html();
        $this.closest( 'ul' ).slideUp();
        $this.closest( '.choose-date-timelist' ).addClass( 'collapsed' );
        $this.closest( '.choose-date-timelist' ).find( 'h3 span.js-time-selection' ).html( timeString2 );
        $this.closest( '.choose-date-timelist' ).find( 'h3' ).slideDown();
        $( '.make-appointment' ).removeClass( 'disabled-button' );
        $( '.make-appointment .gform_next_button' ).attr("disabled",false);
        progressBar(4);

        // Populate hidden date field
        $webFormCalendar3.find( '.js-choose-time' ).focus().val( timeString2 ).attr( 'value', timeString2 ).blur();


        // Animate Scrolldown to Confirmation section
        if( $( window ).width() <= 774 ) {
        // Wait until animation is done
          setTimeout( function() {
            helpers.scrollPageTo( '#confirmation-details h6', 160 );
          }, 300 );
        }
        e.stopPropagation();
      }
    //End Test 5/3/16

      // proceed to schedule for calendar 4 or mobile calendar by pulling data out of the time selection
    if ( $("#webFormCalendar4").hasClass( 'cc-web-form-calendar-month' ) ) {
        var timeString3 = $this.html();
        $this.closest( 'ul' ).slideUp();
        $this.closest( '.choose-date-timelist' ).addClass( 'collapsed' );
        $this.closest( '.choose-date-timelist' ).find( 'h3 span.js-time-selection' ).html( timeString3 );
        $this.closest( '.choose-date-timelist' ).find( 'h3' ).slideDown();
        $( '.make-appointment' ).removeClass( 'disabled-button' );
        $( '.make-appointment .gform_next_button' ).attr("disabled",false);
        progressBar(4);

        // Populate hidden date field
        $("#webFormCalendar4").find( '.js-choose-time' ).focus().val( timeString3 ).attr( 'value', timeString3 ).blur();

        // Animate Scrolldown to Confirmation section
        if( $( window ).width() <= 774 ) {
        // Wait until animation is done
          setTimeout( function() {
            helpers.scrollPageTo( '#confirmation-details h6', 160 );
          }, 300 );
        }
        e.stopPropagation();
    }
  } );

  //Initiate Call Me Now
  $messageBox.find(".call-me-now-box .buttons > .green").click(function(){


    //Validate the section and collect the errors
    var errors = validateSection( "call-me-now" );
    var animationDuration = 300;

    if(errors.length === 0){

      $messageBox.find(".waiting-icon-container").slideDown(animationDuration);
      $messageBox.find(".call-me-now-form").slideUp();

      var leadData = {};

      leadData.action = "cc_salesforce_initiate_call_me_now";

      //If they're just repeating step one.
      if(ccUserInfo.guid !== ''){
        leadData.GUID = ccUserInfo.guid;
      }

      var $callmenowFirstName = $( '#call-me-now-firstName' );
      var $callmenowLastName = $( '#call-me-now-lastName' );
      var $callmenowPhone = $( '#call-me-now-phone' );

      leadData.FirstName = $callmenowFirstName.val();
      leadData.LastName = $callmenowLastName.val();
      leadData.Phone = $callmenowPhone.val();

      if( ccUserInfo.address ){
        leadData.Address = ccUserInfo.address;
      }
      if( ccUserInfo.city ){
        leadData.City = ccUserInfo.city;
      }
      if( ccUserInfo.state ){
        leadData.State = ccUserInfo.state;
      }
      if( ccUserInfo.zipcode ){
        leadData.Zip = ccUserInfo.zipcode;
      }

      if( ccUserInfo.email !== ''){
        leadData.Email = ccUserInfo.email;
      }

      if( ccUserInfo.availableCenters && ccUserInfo.centerID){
        var currentCenterData = ccUserInfo.availableCenters[ ccUserInfo.centerID ];
        leadData.CenterId = currentCenterData.id;
      }
      leadData.testing = window.ccUserInfo.testing;

      jQuery.ajax({
        type: "POST",
        url: ajaxurl,
        data: leadData,
        success: function( msg ){
          $(".call-me-now-box").html("<p>Thank you! You've successfully submitted your request. We look forward to speaking with you shortly.</p><div class='buttons'><div class='dismiss'>OK</div></div>");
          $(".call-me-now-box").find(".buttons > .dismiss").click(function(){
            hideMessageBox();
          });

        },
        error: function(msg){
          $messageBox.find(".waiting-icon-container").slideUp(animationDuration);
          //console.log("Ajax Error initiating Call-Me-Now:");
          $messageBox.find(".call-me-now-form").prepend("<p style='color: red;'>Sorry, something went wrong with your request. Try again later.</p>").slideDown();

          global.dataLayer.push({ 'error_data': msg });
          global.dataLayer.push({ 'error_message': 'Ajax Error initiating Call-Me-Now' });
          global.dataLayer.push({ 'event': 'ajax-error' });
        },
      });
    }else{
      alert("Please complete all required fields to continue.");
    }
  });
}

/**
* Set up to remove elements no longer needed in the two month
* calendar test, and move around those that changed.
*/

const DOBTest = Cookie.get('DOB-Variant');

export function initiateStep3TwoMonthCal() {
 //remove shadow and border from calendar wrapper
 $('.right-wrapper').css("box-shadow","none");
 $('.right-wrapper').css("border","0");
 //hide old header
 $('.month-calendar-right h3').hide();
  //insert new header for ClearChoice selected location
 $( "<p id='clearchoice-location-step3'>Your ClearChoice location:</p>" ).insertBefore(".appt-details .address");
 //hide no longer needed text
 $('.month-calendar-right p').not('.right-wrapper p').not(".appt-details p").hide();
 //detach the agree to terms paragraph to append later
 var p = $( "#confirmation-details p" ).not(".appt-details p").detach();
 //append the paragraph to a new location
 if (!DOBTest) {
  p.appendTo(".field-agree-to-terms .gfield_label");
 } else {
  $(".field-agree-to-terms .gfield_label").hide()
 }
 //hide no longer needed text in right/bottom patient information box
 $( ".form-page-instructions" ).hide();
 $( "#confirmation-details .name" ).hide();
 $( "#confirmation-details .date" ).hide();
 $( "#confirmation-details span" ).hide();
 //align text center for bottom container
 $(' #field_19_17 .gfield_label ').css("text-align","center");
 $(' #field_19_17 p ').css("text-align","center");
 //fix inline CSS style
 $( '#input_19_17 ').attr('style', 'margin:0px !important; padding-top: 0 !important;');
  //align text center for bottom container
  $(' #field_34_17 .gfield_label ').css("text-align","center");
  $(' #field_34_17 p ').css("text-align","center");
  //fix inline CSS style
  $( '#input_34_17 ').attr('style', 'margin:0px !important; padding-top: 0 !important;');

 $('li.gchoice_19_17_1').css("text-align","center");

 $('li.gchoice_34_17_1').css("text-align","center");
 //increase checkmark size for agree to terms input
 $('input#choice_19_17_1').attr('style', 'height: 30px !important; width: 30px !important;');
 $('input#choice_34_17_1').attr('style', 'height: 30px !important; width: 30px !important;');
 //remove border that is no longer needed
 $('.make-appointment #confirmation-details .address p').css("border-bottom","0");
 $('.appt-details').css("padding","0");
 $('.appt-details p').css("padding","0");
 //add in new line break
if (!DOBTest) {
   $( ".field-agree-to-terms" ).prepend('<hr id="agree-terms-line">');
}
 //this part is for fixing the border/shadow that changed to include both calendars and not individual characters.
 //used further in w1_step3_2months to dynamically change containers border/shadows
 $( '#twoMonthCalendarTemplate ').attr('style', ' border: 1px solid #ccc !important;  width: 98%; box-shadow: 0 0 10px #ccc; -webkit-box-shadow: 0 0 10px #ccc; margin-left: 1%; ');
 //change time selection to uppercase
 $( 'span.js-time-selection.js-change-time ').attr('style', ' text-transform: uppercase;')
}

