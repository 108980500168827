import Cookie from "js-cookie";

const DOBTest = Cookie.get('DOB-Variant');

export function defaultMonthCalendar() {
return DOBTest ? `<div class="row justify-content-center">
<div class="col-md-8">
    <p class="w2-only">*Select a Date</p>
    <div id="webFormCalendar" class="cc-web-form-calendar-month">
        <div class="month-calendar-left">
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
            <h6 class="w1-only select-date-time">Select Your Date and Time</h6>
            <p class="date-time-paragraph">Schedule the date and time of your free 60-90 minute consultation</p>
            <p class="select-date">Select your date*</p>
            <div class="choose-date-month">
                <div>
                    <div class="calendar-title-nav">
                        <h4>&nbsp;</h4>
                        <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        <a class="btnNext" title="Later"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
                <div class="month-wrapper">
                    <table>
                        <thead>
                        <tr>
                            <th>SUN</th>
                            <th>MON</th>
                            <th>TUE</th>
                            <th>WED</th>
                            <th>THU</th>
                            <th>FRI</th>
                            <th>SAT</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="row text-center calendar-key-w2">
                        <div class="col-4 col-sm-3">
                            <div class="unavailable-key"></div>
                            <small>Unavailable</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="available-key"></div>
                            <small>Available</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="selected-key"></div>
                            <small>Selected</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <p class="select-time w2-only">*Select a Time</p>


        <div class="form-group mt-2">
            <div id="choose-date--timelist" class="choose-day-timelist">
<p class="select-time">Select your time*</h3>
                <div class="choose-date-timelist">
                    <div class="btn-group btn-group-grid w2-only"></div>
                    <h3 class="date-selection"><span class="js-day-selection"></span></br><span class="js-time-selection"></span></h3>
                    <ul>
                        <li>7:30 am MST</li>
                    </ul>

                </div>
            </div>
        </div>
</div>
<div id="webFormCalendarChangeDateTime" class="col-md-8">
<div class="change-date-time"><span class="change-time-selection js-change-time">Change my date or time</span></div>
<div class="month-calendar-right w1-only">
        <div class="right-wrapper oneMonth">
            <div id="confirmation-details">
            </div>
        </div>
    </div>
    </div>
</div>`
:
`<div class="row justify-content-center">
<div class="col-md-8">
    <p class="w2-only">*Select a Date</p>
    <div id="webFormCalendar" class="cc-web-form-calendar-month">
        <div class="month-calendar-left">
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
            <h6 class="w1-only select-date-time">Select Your Date and Time</h6>
            <p class="date-time-paragraph">Schedule the date and time of your free 60-90 minute consultation</p>
            <p class="select-date">Select your date*</p>
            <div class="choose-date-month">
                <div>
                    <div class="calendar-title-nav">
                        <h4>&nbsp;</h4>
                        <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        <a class="btnNext" title="Later"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                </div>
                <div class="month-wrapper">
                    <table>
                        <thead>
                        <tr>
                            <th>SUN</th>
                            <th>MON</th>
                            <th>TUE</th>
                            <th>WED</th>
                            <th>THU</th>
                            <th>FRI</th>
                            <th>SAT</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="row text-center calendar-key-w2">
                        <div class="col-4 col-sm-3">
                            <div class="unavailable-key"></div>
                            <small>Unavailable</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="available-key"></div>
                            <small>Available</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="selected-key"></div>
                            <small>Selected</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <p class="select-time w2-only">*Select a Time</p>


        <div class="form-group mt-2">
            <div id="choose-date--timelist" class="choose-day-timelist">
<p class="select-time">Select your time*</h3>
                <div class="choose-date-timelist">
                    <div class="btn-group btn-group-grid w2-only"></div>
                    <h3 class="date-selection"><span class="js-day-selection"></span></br><span class="js-time-selection"></span></h3>
                    <ul>
                        <li>7:30 am MST</li>
                    </ul>

                </div>
            </div>
        </div>
</div>
<div id="webFormCalendarChangeDateTime" class="col-md-8">
<div class="change-date-time"><span class="change-time-selection js-change-time">Change my date or time</span></div>
<div class="month-calendar-right w1-only">
        <div class="right-wrapper oneMonth">
            <div id="confirmation-details">
            </div>
        </div>
    </div>
    </div>
</div>
<div class="row">
<div class="col-12 no-appts-number"><p style="padding:0; margin-bottom:-20px; font-weight:400;">Can't find an appointment that works with your schedule? Call us at <a style="color:#1a72b5 !important; text-decoration:none; font-weight:600;" id="calendar-phone-number" href="tel:888-651-9950">888-651-9950</a></p></div>
</div>`
;
}

export function twoMonthCalendar() {
    return DOBTest ? `<div id="twoMonthCalendarTemplate" class="row mb-4">
    <div id="cal-left-wrap" class="col-md-6">
        <div id="webFormCalendar2" class="cc-web-form-calendar-month">
            <div class="month-calendar-left">
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
                <div class="choose-date-month">
                    <div>
                        <div class="calendar-title-nav">
                            <h4>&nbsp;</h4>
                            <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        </div>
                        <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                    </div>
                    <div class="month-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="calendar-separator">
    <div id="cal-right-wrap" class="col-md-6">
        <div id="webFormCalendar3" class="cc-web-form-calendar-month">
            <div class="month-calendar-right">
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
                <div class="choose-date-month">
                    <div>
                        <div class="calendar-title-nav">
                            <h4>&nbsp;</h4>
                            <a class="btnNext" title="Later"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                        </div>
                        <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                    </div>
                    <div class="month-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="moreDates"><span class="showMoreDates">Show More Dates</span></div>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="bottom-submit-text" class="row">
    <div class="col-12">
        <div class="month-calendar-right w1-only" style="text-align: center; justify-content: center;">
             <h3>Finalize Your Appointment</h3>
            <p style="margin-top:-10px; color:red;">*Your consultation is not reserved until you click the Schedule button below </p>
            <div class="right-wrapper">
                <div id="confirmation-details">
                    <div class="appt-details">
                        <div class="name">&nbsp;</div>
                        <div class="date">&nbsp;</div>
                        <div class="address" style"text-align:center; justify-content:center;">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="form-group mt-2">
            <div id="choose-date--timelist" class="choose-day-timelist">
                <div class="choose-date-timelist">
                    <div class="btn-group btn-group-grid w2-only"></div>
                    <h3 class="date-selection"><span class="js-time-selection js-change-time"></span><span class="change-time-selection js-change-time">Change</span></h3>
                    <ul>
                        <li>7:30 am MST</li>
                    </ul>

                </div>
            </div>
        </div>
        <p class="text-md-right w2-only">Appointments are approximately 1 hour</p>
    </div>
</div>`
:
`<div id="twoMonthCalendarTemplate" class="row mb-4">
    <div id="cal-left-wrap" class="col-md-6">
        <div id="webFormCalendar2" class="cc-web-form-calendar-month">
            <div class="month-calendar-left">
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
                <div class="choose-date-month">
                    <div>
                        <div class="calendar-title-nav">
                            <h4>&nbsp;</h4>
                            <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        </div>
                        <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                    </div>
                    <div class="month-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="calendar-separator">
    <div id="cal-right-wrap" class="col-md-6">
        <div id="webFormCalendar3" class="cc-web-form-calendar-month">
            <div class="month-calendar-right">
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
                <div class="choose-date-month">
                    <div>
                        <div class="calendar-title-nav">
                            <h4>&nbsp;</h4>
                            <a class="btnNext" title="Later"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                        </div>
                        <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                    </div>
                    <div class="month-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="moreDates"><span class="showMoreDates">Show More Dates</span></div>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="bottom-submit-text" class="row">
    <div class="col-12">
        <div class="month-calendar-right w1-only" style="text-align: center; justify-content: center;">
             <h3>Finalize Your Appointment</h3>
            <p style="margin-top:-10px; color:red;">*Your consultation is not reserved until you click the Schedule button below </p>
            <div class="right-wrapper">
                <div id="confirmation-details">
                    <div class="appt-details">
                        <div class="name">&nbsp;</div>
                        <div class="date">&nbsp;</div>
                        <div class="address" style"text-align:center; justify-content:center;">&nbsp;</div>
                    </div>
                    <p style"text-align:center; justify-content:center;">After pressing “Schedule My Free Consultation” below, we will hold an hour of the doctor’s time for you. A representative from ClearChoice will attempt to contact you closer to your appointment date to confirm this appointment will still work for you. If you are unable to make your appointment, please call us at <span id="cancellation-phone-number">888-651-9950</span> with at least 48 hours notice.</p>
                </div>
            </div>
        </div>


        <div class="form-group mt-2">
            <div id="choose-date--timelist" class="choose-day-timelist">
                <div class="choose-date-timelist">
                    <div class="btn-group btn-group-grid w2-only"></div>
                    <h3 class="date-selection"><span class="js-time-selection js-change-time"></span><span class="change-time-selection js-change-time">Change</span></h3>
                    <ul>
                        <li>7:30 am MST</li>
                    </ul>

                </div>
            </div>
        </div>
        <p class="text-md-right w2-only">Appointments are approximately 1 hour</p>
    </div>
    </div>
    ${window.location.pathname.includes('schedule-now')
    ?  `<div class="row">
        <div class="col-12 no-appts-number-now"><p style="padding:0; margin-bottom:-20px; font-weight:400;">Can't find an appointment that works with your schedule?<p><p>Call us at <a style="color:#1a72b5 !important; text-decoration:none; font-weight:600;" id="calendar-phone-number" href="tel:888-651-9950">888-651-9950</a></p></div>
      </div>`
    : `<div class="row">
        <div class="col-12 no-appts-number"><p>Can't find an appointment that works with your schedule? Call us at <a style="color:#1a72b5 !important; text-decoration:none; font-weight:600;" id="calendar-phone-number" href="tel:888-651-9950">888-651-9950</a></p></div>
      </div>`}`;
    }


export function oneMonthCalendarWeekControls() {
return DOBTest ? `<div class="row mb-4">
        <p class="w2-only">*Select a Date</p>
        <div id="webFormCalendar" class="cc-web-form-calendar-month">
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
            <h3 class="w1-only">Select a date</h3>
            <div class="choose-date-month">
                <div>
                    <div class="calendar-title-nav">
                        <h4>&nbsp;</h4>
                        <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        <a class="btnNext" title="Later"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                    <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                </div>
                <div class="month-wrapper">
                    <table>
                        <thead>
                        <tr>
                            <th>S</th>
                            <th>M</th>
                            <th>T</th>
                            <th>W</th>
                            <th>T</th>
                            <th>F</th>
                            <th>S</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="calendar-key">
                        <div class="available-key">Available</div>
                        <div class="full-key">Full</div>
                        <div class="closed-key">Closed</div>
                        <div class="today-key">Today</div>
                    </div>
                    <div class="row text-center calendar-key-w2">
                        <div class="col-4 col-sm-3">
                            <div class="unavailable-key"></div>
                            <small>Unavailable</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="available-key"></div>
                            <small>Available</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="selected-key"></div>
                            <small>Selected</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
             <h3>Finalize Your Appointment</h3>
            <p style="margin-top:-10px; color:red;">*Your consultation is not reserved until you click the Schedule button below </p>
            <div class="right-wrapper">
                <div id="confirmation-details">
                    <div class="appt-details oneMonth">
                        <div class="appt-details-header">Your ClearChoice Location:</div>
                        <div class="address">&nbsp;</div>
                    </div>
                </div>
            </div>
        <p class="select-time w2-only">*Select a Time</p>


        <div class="form-group mt-2">
            <div id="choose-date--timelist" class="choose-day-timelist">
<h3>Select an available time</h3>
                <div class="choose-date-timelist">
                    <div class="btn-group btn-group-grid w2-only"></div>
                    <h3 class="date-selection"><span class="js-time-selection js-change-time"></span><span class="change-time-selection js-change-time">Change</span></h3>
                    <ul>
                        <li>7:30 am MST</li>
                    </ul>

                </div>
            </div>
        </div>
        <p class="text-md-right w2-only">Appointments are approximately 1 hour</p>
</div>`
:
`<div class="row mb-4">
        <p class="w2-only">*Select a Date</p>
        <div id="webFormCalendar" class="cc-web-form-calendar-month">
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
            <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
            <h3 class="w1-only">Select a date</h3>
            <div class="choose-date-month">
                <div>
                    <div class="calendar-title-nav">
                        <h4>&nbsp;</h4>
                        <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        <a class="btnNext" title="Later"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                    <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                </div>
                <div class="month-wrapper">
                    <table>
                        <thead>
                        <tr>
                            <th>S</th>
                            <th>M</th>
                            <th>T</th>
                            <th>W</th>
                            <th>T</th>
                            <th>F</th>
                            <th>S</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="colSunday">&nbsp;</td>
                            <td class="colMonday">&nbsp;</td>
                            <td class="colTuesday">&nbsp;</td>
                            <td class="colWednesday">&nbsp;</td>
                            <td class="colThursday">&nbsp;</td>
                            <td class="colFriday">&nbsp;</td>
                            <td class="colSaturday">&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="calendar-key">
                        <div class="available-key">Available</div>
                        <div class="full-key">Full</div>
                        <div class="closed-key">Closed</div>
                        <div class="today-key">Today</div>
                    </div>
                    <div class="row text-center calendar-key-w2">
                        <div class="col-4 col-sm-3">
                            <div class="unavailable-key"></div>
                            <small>Unavailable</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="available-key"></div>
                            <small>Available</small>
                        </div>
                        <div class="col-4 col-sm-3">
                            <div class="selected-key"></div>
                            <small>Selected</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
             <h3>Finalize Your Appointment</h3>
            <p style="margin-top:-10px; color:red;">*Your consultation is not reserved until you click the Schedule button below </p>
            <div class="right-wrapper">
                <div id="confirmation-details">
                    <div class="appt-details oneMonth">
                        <div class="appt-details-header">Your ClearChoice Location:</div>
                        <div class="address">&nbsp;</div>
                    </div>
                    <p>After pressing “Schedule My Free Consultation” below, we will hold an hour of the doctor’s time for you. A representative from ClearChoice will attempt to contact you closer to your appointment date to confirm this appointment will still work for you. If you are unable to make your appointment, please call us at <span id="cancellation-phone-number">888-651-9950</span> with at least 48 hours notice.</p>
                </div>
            </div>
        <p class="select-time w2-only">*Select a Time</p>


        <div class="form-group mt-2">
            <div id="choose-date--timelist" class="choose-day-timelist">
<h3>Select an available time</h3>
                <div class="choose-date-timelist">
                    <div class="btn-group btn-group-grid w2-only"></div>
                    <h3 class="date-selection"><span class="js-time-selection js-change-time"></span><span class="change-time-selection js-change-time">Change</span></h3>
                    <ul>
                        <li>7:30 am MST</li>
                    </ul>

                </div>
            </div>
        </div>
        <p class="text-md-right w2-only">Appointments are approximately 1 hour</p>
</div>
<div class="row">
    <div class="col-12 no-appts-number"><p style="padding:0; margin-bottom:-20px; font-weight:400;">Can't find an appointment that works with your schedule? Call us at <a style="color:#1a72b5 !important; text-decoration:none; font-weight:600;" id="calendar-phone-number" href="tel:888-651-9950">888-651-9950</a></p></div>
</div>`;
}
