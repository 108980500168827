// import grecaptcha from 'https://www.google.com/recaptcha/api.js';
import {showMessageBox} from '../form/formFunctions';

export function initRecaptcha() {
  global.captchaLock = new $.Deferred();
  $("#recaptcha").trigger('renderRecaptcha');

  $("#recaptcha").one('processRecaptcha', function(event,token) {
    processRecaptchaToken(token);
  });

  // $.when(global.captchaLock).done(function(captchaSuccess) {
  //   if(captchaSuccess){

  //   }
  // });

}

function processRecaptchaToken(token){

  var captchaData = {
    action: 'cc_process_recaptcha_token',
    token: token,
  };
  console.log(captchaData);
  $.ajax({
    type: 'POST',
    url: ajaxurl,
    dataType: 'json',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: captchaData,
    cache: false,
    headers: { "cache-control": "no-cache" },
    success: function(msg) {
      console.log(msg);
      // global.captchaLock.resolve(true);
      if(msg.message == 'success'){
        global.captchaCompleted = true;
        window.captchaCompleted = true;
        $('.gform_next_button').eq(0).click();
      }else{
        //placeholder. put in state for failed user
        captchaFailMessage();
      }
    },
    error: function(msg) {
       //placeholder. put in state for failed user
       captchaFailMessage();
    },
  });
}

function captchaFailMessage(){
  var $messageBox = $("#webform-component-messages");
  var callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

  $messageBox.find( '.message-text' ).html('<div class="alert alert-danger" role="alert">Your submission has failed our captcha verification. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
  showMessageBox();
  global.dataLayer.push({ 'event': 'Captcha Failed' });
  $(".gform_next_button").attr('disabled',true);
}
