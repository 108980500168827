import Cookie from 'js-cookie';
import {processAppointments} from './processAppointments';
import {flagGYIM} from './flagGYIM';
import {setGlobalGAInfo,showMessageBox,hideMessageBox,showCallMeNow,loadCallMeNowForm,schedulingDisabledPopup,initDateTimeField,schedulingDisabledCampaignPopup,schedulingDisabledExistingAppt} from './formFunctions';
import queryString from 'query-string';
import {showWaitingIcon,hideWaitingIcon} from './w1_helper_functions';
import {saveCampaign,showTextMessageTermsBox,showTextConsentCheckboxTest,createUuid} from './formFunctions';
import {retrieveUnfilteredAppiontmentsNearest} from './retrieveAppointments';

export function saveLeadInfo() {

  window['optimizely'] = window['optimizely'] || [];
  window['optimizely'].push({
    type: "event",
    eventName: "pageGatherInfoComletion",
  });

  global.saveLeadInfoLock = new $.Deferred();
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
  // console.log(callingelement)
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtInitAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInitAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtInitAjax++;

  let leadData = getLeadData();
  let experimentData = {};
  leadData.scheduleTest = Cookie.get('scheduleTest');

  leadData.action = 'cc_salesforce_save_lead';
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */
  var leadSegment,leadValue, leadSegment20, leadSegment5, collectionsPerLead;
  // Send optimizely and other data to salesforce.
  var experimentID =  global.dataLayer.findLast(function(e){if(e['experiment.id'] != undefined) { return (e)}} ) != undefined ? global.dataLayer.findLast(function(e){if(e['experiment.id'] != undefined) { return (e)}} )['experiment.id'] : undefined;
  var experimentIDVWO =  (global._vwo_campaignData != undefined || global._vwo_campaignData != "") && typeof global._vwo_campaignData == 'object' ? Object.keys(global._vwo_campaignData)[0] : undefined;
  experimentID = experimentIDVWO == undefined ||  experimentIDVWO == "" ? experimentID : experimentIDVWO;
  var experimentVariation =  global.dataLayer.findLast(function(e){if(e['experiment.variation'] != undefined) { return (e)}} ) != undefined ? global.dataLayer.findLast(function(e){if(e['experiment.variation'] != undefined) { return (e)}} )['experiment.variation'] : undefined;
  var experimentVariationVWO;
  if(global._vwo_campaignData != undefined && typeof global._vwo_campaignData == 'object'){
    if(global._vwo_campaignData[Object.keys(global._vwo_campaignData)[0]] != undefined){
      if(global._vwo_campaignData[Object.keys(global._vwo_campaignData)[0]].c != undefined){
        experimentVariationVWO = global._vwo_campaignData[Object.keys(global._vwo_campaignData)[0]].c.toString();
      }
    }
  }
  if(experimentVariationVWO != undefined && experimentVariationVWO != "" && experimentVariation == undefined || experimentVariation == ""){
    experimentVariation = experimentVariationVWO;
  } else if(experimentVariationVWO != undefined && experimentVariationVWO != "" && experimentVariation != undefined){
    experimentVariation = experimentVariationVWO;
  } else {
    //relax
  }
  var referrerPage = document.referrer;
  var conversionPage = window.location.pathname;
  // check if cookie exists for this as well, capture smile folks
  let cookiePattern = /_vis_opt_exp_[0-9]+_split/i;
  var cookies=document.cookie.split(/;\s*/), ret=[], i;
  var expData;
  for (i=0; i<cookies.length; i++) {
    if (cookies[i].match(cookiePattern)) {
      ret.push(cookies[i]);
    }
  }
  if(ret.length > 0){
    expData = ret[0].match(/\d+/g);
  }
  if(Cookie.get('cc-experiment-id') != undefined && Cookie.get('cc-experiment-id') != ""){
    experimentData.experimentID = Cookie.get('cc-experiment-id');
  }
  if(experimentID != undefined ){
    experimentData.experimentID = experimentID.toString();
  }
  if(Cookie.get('cc-experiment-variation') != undefined && Cookie.get('cc-experiment-variation') != ""){
    experimentData.experimentVariation = Cookie.get('cc-experiment-variation');
  }
  if(experimentVariation != undefined){
    experimentData.experimentVariation = experimentVariation;
  }
  // This takes priority above all else if it exists
  if(expData != undefined && expData.length == 2){
    experimentData.experimentID = expData[0];
    experimentData.experimentVariation = expData[1];
  }
  if(referrerPage != undefined){
    experimentData.referrerPage = referrerPage;
  }
  if(conversionPage != undefined){
    experimentData.conversionPage = conversionPage;
  }
  experimentData.timestampFirstTouch = new Date().getTime().toString();
  if(!jQuery.isEmptyObject(experimentData)){
    leadData.experimentData = JSON.stringify(experimentData);
  }
  if (global.ccUserInfo.attemptsAtInitAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        shouldContinue = true;
        let retrievedGUID;
        if(msg.errorMessage != undefined ){
          if(msg.errorMessage == 'update error'){
            global.ccUserInfo.retryId = msg.retryId;
          }
          global.ccUserInfo.retryCall = true;
          saveLeadInfo();
        }
        if (msg.totalSize > 0) {
          window['optimizely'] = window['optimizely'] || [];
          window['optimizely'].push({
            type: "event",
            eventName: "goal13LeadCreated",
          });
          retrievedGUID = msg.records[0].Id;
          Cookie.set('GUID', window.btoa(retrievedGUID));
          Cookie.set('userId', retrievedGUID);
          global.ccUserInfo.guid = retrievedGUID;
          global.ccUserInfo.leadScore = msg.leadScore;
          global.ccUserInfo.sf_gy = msg.sf_gy;
          global.ccUserInfo.model2ScoreInfo = msg.model2ScoreInfo;
          let address = new Object();
          address.zip = global.ccUserInfo.zipcode;
          address.city = global.ccUserInfo.city;
          address.state = global.ccUserInfo.state;
          if(Cookie.get('scheduleTest')){
            retrieveUnfilteredAppiontmentsNearest(JSON.stringify(address),retrievedGUID);
          }
          // ga('set','userId',retrievedGUID);
          global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
          global.dataLayer.push({ 'salesforce_guid': retrievedGUID,
          'UserId':retrievedGUID});
          global.dataLayer.push({ 'lead_type': 'Traditional' });
          global.dataLayer.push({ 'event': 'lead_create' });

          if (msg.records[0].IsConverted) {
            global.ccUserInfo.contactID = msg.records[0].ConvertedContactId;
            global.ccUserInfo.accountID = msg.records[0].ConvertedAccountId;
          }
          global.ccUserInfo.appointmentScheduled = msg.records[0].Appointment_Scheduled__c;
          global.ccUserInfo.consultInventory = msg.records[0].Consult_Inventory__r;

          global.saveLeadInfoLock.resolve(true);
          global.saveLeadInfoDone = true;
          //temporary code for campaign
          var checkForSmarty = Cookie.get('test-smarty-streets');
          if((!checkForSmarty || typeof checkForSmarty == 'undefined')){
            //no address autocompete
          }else{
            saveCampaign('SmartyStreets');
          }
          if(global.switchedSteps){
            leadValue = msg.monetaryValue;
            collectionsPerLead = msg.collectionsPerLead;
            leadSegment = msg.leadSegment;
            leadSegment20 = msg.leadSegment20;
            leadSegment5 = msg.leadSegment5;
            global.dataLayer.push({ 'Lead Value': leadValue});
            global.dataLayer.push({ 'Lead Segment': leadSegment});
            global.dataLayer.push({ 'Lead Segment 20': leadSegment20});
            global.dataLayer.push({ 'Collections Per Lead': collectionsPerLead});
            global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
            global.ccUserInfo.leadSegment5 = parseFloat(msg.leadSegment5);
            global.ccUserInfo.leadSegment20 = parseFloat(msg.leadSegment20);
            global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
            global.ccUserInfo.leadScore = msg.leadScore;
            global.ccUserInfo.collectionsPerLead = msg.collectionsPerLead;

            global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
            global.dataLayer.push({ 'collectionsPerLead':   global.ccUserInfo.collectionsPerLead});
            global.dataLayer.push({ 'orderNumber': createUuid()});
            global.dataLayer.push({ 'event': 'personal_info_given'});

          }

          saveLeadAdditionalInfo();

        } else {
          if (typeof msg !== 'undefined' && typeof msg.success !== 'undefined' && msg.success === true) {
            window['optimizely'] = window['optimizely'] || [];
            window['optimizely'].push({
              type: "event",
              eventName: "goal13LeadCreated",
            });
            retrievedGUID = msg.id;
            Cookie.set('GUID', window.btoa(retrievedGUID));
            Cookie.set('userId', retrievedGUID);
            global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
            global.dataLayer.push({ 'salesforce_guid': retrievedGUID, 'UserId':retrievedGUID});
            global.dataLayer.push({ 'lead_type': 'Traditional' });
            global.dataLayer.push({ 'event': 'lead_create' });
            global.ccUserInfo.guid = retrievedGUID;

            ccUserInfo.sf_gy = msg.sf_gy;
            global.ccUserInfo.leadScore = msg.leadScore;
            global.ccUserInfo.model2ScoreInfo = msg.model2ScoreInfo;
            let address = new Object();
            address.zip = global.ccUserInfo.zipcode;
            address.city = global.ccUserInfo.city;
            address.state = global.ccUserInfo.state;
            if(Cookie.get('scheduleTest')){
              retrieveUnfilteredAppiontmentsNearest(JSON.stringify(address),retrievedGUID);
            }

            if(global.switchedSteps){
              leadValue = msg.monetaryValue;
              collectionsPerLead = msg.collectionsPerLead;
              leadSegment = msg.leadSegment;
              leadSegment20 = msg.leadSegment20;
              leadSegment5 = msg.leadSegment5;
              global.dataLayer.push({ 'Lead Value': leadValue});
              global.dataLayer.push({ 'Lead Segment': leadSegment});
              global.dataLayer.push({ 'Lead Segment 20': leadSegment20});
              global.dataLayer.push({ 'Collections Per Lead': collectionsPerLead});
              global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
              global.ccUserInfo.leadSegment5 = parseFloat(msg.leadSegment5);
              global.ccUserInfo.leadSegment20 = parseFloat(msg.leadSegment20);
              global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
              global.ccUserInfo.leadScore = msg.leadScore;
              global.ccUserInfo.collectionsPerLead = msg.collectionsPerLead;

              global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
              global.dataLayer.push({ 'collectionsPerLead':   global.ccUserInfo.collectionsPerLead});
              global.dataLayer.push({ 'orderNumber': createUuid()});
              global.dataLayer.push({ 'event': 'personal_info_given'});
            }

            global.saveLeadInfoLock.resolve(true);
            global.saveLeadInfoDone = true;
             //temporary code for campaign
            var checkForSmarty2 = Cookie.get('test-smarty-streets');
            if((!checkForSmarty2 || typeof checkForSmarty2 == 'undefined')){
              //no address autocompete
            }else{
              saveCampaign('SmartyStreets');
            }
            //console.log("test savelead additional info");
            saveLeadAdditionalInfo();
          } else {
            var flag = false;

            if (typeof msg[0] !== 'undefined' &&
              (typeof msg[0].errors[0] !== 'undefined') &&
              (typeof msg[0].errors[0].statusCode !== 'undefined') &&
              (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
            ) {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            } else if (global.ccUserInfo.attemptsAtInitAjax < 2) {
              saveLeadInfo('');
            } else {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
              global.saveLeadInfoLock.resolve(false);
              global.saveLeadInfoDone = false;
            }

            if (flag) {
              global.dataLayer.push({ "error_data": msg });
              global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
              global.dataLayer.push({ "event": "ajax-error" });
              shouldContinue = false;
            }
          }
        }
        if(msg == "failed to save lead" || msg == "failed"){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtInitAjax < 2) {
          saveLeadInfo('');
        } else {
          global.saveLeadInfoLock.resolve(false);
          global.saveLeadInfoDone = false;
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }else{
    //console.log("still trying to many times");
    global.saveLeadInfoLock.resolve(false);
    global.saveLeadInfoDone = false;
    $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

    global.dataLayer.push({ "error_data": 'failedRetry' });
    global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
    global.dataLayer.push({ "event": "ajax-error" });
  }

}

export function saveUpdateLeadInfoAndRetrieveInfo() {
  global.saveLeadInfoLock = new $.Deferred();
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

  if (typeof global.ccUserInfo.attemptsAtInitAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInitAjax = 0;
  }

  // set up the variable
  global.ccUserInfo.attemptsAtInitAjax++;

  var leadData = {};
  let experimentData = {};
  leadData.leadId = global.ccUserInfo.retrievedLeadId;
  leadData.accountId = global.ccUserInfo.accountID;
  leadData.newLead = global.ccUserInfo.newLead;
  leadData.FewQuestions = global.ccUserInfo.fewQuestions;
  leadData.securityToken = global.securityToken;
  leadData.spokenLanguage = global.ccUserInfo.spokenLanguage;
  // Send optimizely and other data to salesforce.
  // Send optimizely and other data to salesforce.
  var experimentID =  global.dataLayer.findLast(function(e){if(e['experiment.id'] != undefined) { return (e)}} ) != undefined ? global.dataLayer.findLast(function(e){if(e['experiment.id'] != undefined) { return (e)}} )['experiment.id'] : undefined;
  var experimentIDVWO =  (global._vwo_campaignData != undefined || global._vwo_campaignData != "") && typeof global._vwo_campaignData == 'object' ? Object.keys(global._vwo_campaignData)[0] : undefined;
  experimentID = experimentIDVWO == undefined ||  experimentIDVWO == "" ? experimentID : experimentIDVWO;
  var experimentVariation =  global.dataLayer.findLast(function(e){if(e['experiment.variation'] != undefined) { return (e)}} ) != undefined ? global.dataLayer.findLast(function(e){if(e['experiment.variation'] != undefined) { return (e)}} )['experiment.variation'] : undefined;
  var experimentVariationVWO;
  if(global._vwo_campaignData != undefined && typeof global._vwo_campaignData == 'object'){
    if(global._vwo_campaignData[Object.keys(global._vwo_campaignData)[0]] != undefined){
      if(global._vwo_campaignData[Object.keys(global._vwo_campaignData)[0]].c != undefined){
        experimentVariationVWO = global._vwo_campaignData[Object.keys(global._vwo_campaignData)[0]].c.toString();
      }
    }
  }
  if(experimentVariationVWO != undefined && experimentVariationVWO != "" && experimentVariation == undefined || experimentVariation == ""){
    experimentVariation = experimentVariationVWO;
  } else if(experimentVariationVWO != undefined && experimentVariationVWO != "" && experimentVariation != undefined){
    experimentVariation = experimentVariationVWO;
  } else {
    //relax
  }
  var referrerPage = document.referrer;
  var conversionPage = window.location.pathname;
  // check if cookie exists for this as well, capture smile folks
  let cookiePattern = /_vis_opt_exp_[0-9]+_split/i;
  var cookies=document.cookie.split(/;\s*/), ret=[], i;
  var expData;
  for (i=0; i<cookies.length; i++) {
    if (cookies[i].match(cookiePattern)) {
      ret.push(cookies[i]);
    }
  }
  if(ret.length > 0){
    expData = ret[0].match(/\d+/g);
  }
  if(Cookie.get('cc-experiment-id') != undefined && Cookie.get('cc-experiment-id') != ""){
    experimentData.experimentID = Cookie.get('cc-experiment-id');
  }
  if(experimentID != undefined ){
    experimentData.experimentID = experimentID.toString();
  }
  if(Cookie.get('cc-experiment-variation') != undefined && Cookie.get('cc-experiment-variation') != ""){
    experimentData.experimentVariation = Cookie.get('cc-experiment-variation');
  }
  if(experimentVariation != undefined){
    experimentData.experimentVariation = experimentVariation;
  }
  // This takes priority above all else if it exists
  if(expData != undefined && expData.length == 2){
    experimentData.experimentID = expData[0];
    experimentData.experimentVariation = expData[1];
  }
  if(referrerPage != undefined){
    experimentData.referrerPage = referrerPage;
  }
  if(conversionPage != undefined){
    experimentData.conversionPage = conversionPage;
  }
  experimentData.timestampFirstTouch = new Date().getTime().toString();
  if(!jQuery.isEmptyObject(experimentData)){
    leadData.experimentData = JSON.stringify(experimentData);
  }
  leadData.action = 'cc_salesforce_personal_info_step_update_lead_info';
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */
  var leadSegment,leadValue, leadSegment20, leadSegment5, collectionsPerLead;

  if (global.ccUserInfo.attemptsAtInitAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        shouldContinue = true;
        let retrievedGUID;
        if (msg.totalSize > 0) {
          retrievedGUID = msg.records[0].Id;
          Cookie.set('GUID', window.btoa(retrievedGUID));
          Cookie.set('userId', retrievedGUID);
          global.ccUserInfo.guid = retrievedGUID;
          global.ccUserInfo.leadScore = msg.leadScore;
          global.ccUserInfo.sf_gy = msg.sf_gy;
          // ga('set','userId',retrievedGUID);
          global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
          global.dataLayer.push({ 'salesforce_guid': retrievedGUID,
          'UserId':retrievedGUID});
          global.dataLayer.push({ 'lead_type': 'Traditional' });
          global.dataLayer.push({ 'event': 'lead_create' });
          if (msg.records[0].IsConverted) {
            global.ccUserInfo.contactID = msg.records[0].ConvertedContactId;
            global.ccUserInfo.accountID = msg.records[0].ConvertedAccountId;
          }
          global.ccUserInfo.appointmentScheduled = msg.records[0].Appointment_Scheduled__c;
          global.ccUserInfo.consultInventory = msg.records[0].Consult_Inventory__r;

          if(global.switchedSteps){
            leadValue = msg.monetaryValue;
            collectionsPerLead = msg.collectionsPerLead;
            leadSegment = msg.leadSegment;
            leadSegment20 = msg.leadSegment20;
            leadSegment5 = msg.leadSegment5;
            global.dataLayer.push({ 'Lead Value': leadValue});
            global.dataLayer.push({ 'Lead Segment': leadSegment});
            global.dataLayer.push({ 'Lead Segment 20': leadSegment20});
            global.dataLayer.push({ 'Collections Per Lead': collectionsPerLead});
            global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
            global.ccUserInfo.leadSegment5 = parseFloat(msg.leadSegment5);
            global.ccUserInfo.leadSegment20 = parseFloat(msg.leadSegment20);
            global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
            global.ccUserInfo.leadScore = msg.leadScore;
            global.ccUserInfo.collectionsPerLead = msg.collectionsPerLead;

            global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
            global.dataLayer.push({ 'collectionsPerLead':   global.ccUserInfo.collectionsPerLead});
            global.dataLayer.push({ 'orderNumber': createUuid()});
            global.dataLayer.push({ 'event': 'personal_info_given'});
          }

          global.saveLeadInfoLock.resolve(true);
          Cookie.remove('cc-pre-form');
          Cookie.remove('userFrontEndId');
          global.saveLeadInfoDone = true;
           //temporary code for campaign
           var checkForSmarty3 = Cookie.get('test-smarty-streets');
           if((!checkForSmarty3 || typeof checkForSmarty3 == 'undefined')){
             //no address autocompete
           }else{
             saveCampaign('SmartyStreets');
           }
          saveLeadAdditionalInfo();

        }else {
          var flag = false;

          if (typeof msg[0] !== 'undefined' &&
            (typeof msg[0].errors[0] !== 'undefined') &&
            (typeof msg[0].errors[0].statusCode !== 'undefined') &&
            (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
          ) {
            $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
            flag = true;
          } else if (global.ccUserInfo.attemptsAtInitAjax < 2) {
            saveUpdateLeadInfoAndRetrieveInfo('');
          } else {
            $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
            flag = true;
            global.saveLeadInfoLock.resolve(false);
            global.saveLeadInfoDone = false;
            Cookie.remove('cc-pre-form');
            Cookie.remove('userFrontEndId');
          }

          if (flag) {
            global.dataLayer.push({ "error_data": msg });
            global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
            global.dataLayer.push({ "event": "ajax-error" });
            shouldContinue = false;
          }
        }

        if(msg == "failed to save lead" || msg == "failed"){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtInitAjax < 2) {
          saveUpdateLeadInfoAndRetrieveInfo('');
        } else {
          //console.log("still trying to many times");
          global.saveLeadInfoLock.resolve(false);
          global.saveLeadInfoDone = false;
          Cookie.remove('cc-pre-form');
          Cookie.remove('userFrontEndId');
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }

}

export function saveLeadInfoPartial() {
  global.dataLayer.push({ 'lead_type': 'Partial' });
  global.dataLayer.push({ 'event': 'lead_create' });
  global.saveLeadInfoLock = new $.Deferred();
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
  // console.log(callingelement)
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtInitAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInitAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtInitAjax++;

  let leadData = getLeadData();
  let experimentData = {};
  leadData.isPartialForm = true;
  leadData.userLeadReserveFrontEndUid = Cookie.get('userFrontEndId');
  leadData.scheduleTest = Cookie.get('scheduleTest');

  leadData.action = 'cc_salesforce_save_lead_partial';
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */
  // add tracking for partial lead to catch all before actual success.
  if (global.ccUserInfo.attemptsAtInitAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        shouldContinue = true;
        let retrievedGUID;
        if (msg.size > 0) {
          retrievedGUID = msg.records[0].Id;
          Cookie.set('GUID', window.btoa(retrievedGUID));
          Cookie.set('userId', retrievedGUID);
          global.ccUserInfo.guid = retrievedGUID;
          global.ccUserInfo.sf_gy = msg.records[0].sf_gy
          // ga('set','userId',retrievedGUID);
          global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
          global.dataLayer.push({ 'salesforce_guid': retrievedGUID,
          'UserId':retrievedGUID});

          if (msg.records[0].IsConverted) {
            global.ccUserInfo.contactID = msg.records[0].ConvertedContactId;
            global.ccUserInfo.accountID = msg.records[0].ConvertedAccountId;
          }
          global.ccUserInfo.appointmentScheduled = msg.records[0].Appointment_Scheduled__c;
          global.ccUserInfo.consultInventory = msg.records[0].Consult_Inventory__r;

          global.saveLeadInfoLock.resolve(true);
          global.saveLeadInfoDone = true;
          saveLeadAdditionalInfo();

        } else {
          if (typeof msg[0] !== 'undefined' && typeof msg[0].success !== 'undefined' && msg[0].success === true) {
            retrievedGUID = msg[0].id;
            Cookie.set('GUID', window.btoa(retrievedGUID));
            Cookie.set('userId', retrievedGUID);
            global.dataLayer.push({ 'User Id DataLayer': retrievedGUID});
            global.dataLayer.push({ 'salesforce_guid': retrievedGUID, 'UserId':retrievedGUID});
            global.ccUserInfo.guid = retrievedGUID;

            ccUserInfo.sf_gy = msg[0].sf_gy;

            global.saveLeadInfoLock.resolve(true);
            global.saveLeadInfoDone = true;
            saveLeadAdditionalInfo();
          } else {
            var flag = false;

            if (typeof msg[0] !== 'undefined' &&
              (typeof msg[0].errors[0] !== 'undefined') &&
              (typeof msg[0].errors[0].statusCode !== 'undefined') &&
              (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
            ) {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            } else if (global.ccUserInfo.attemptsAtInitAjax < 2) {
              saveLeadInfo('');
            } else {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
              global.saveLeadInfoLock.resolve(false);
              global.saveLeadInfoDone = false;
            }

            if (flag) {
              global.dataLayer.push({ "error_data": msg });
              global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
              global.dataLayer.push({ "event": "ajax-error" });
              shouldContinue = false;
            }
          }
        }
        if(msg == "failed to save lead" || msg == "failed"){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtInitAjax < 2) {
          saveLeadInfo('');
        } else {
          global.saveLeadInfoLock.resolve(false);
          global.saveLeadInfoDone = false;
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  }

}

export function saveLeadAdditionalInfo() {
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtInfoAjax === 'undefined') {
    global.ccUserInfo.attemptsAtInfoAjax = 0;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtInfoAjax++;

  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  let leadData = getLeadData();

  leadData.action = 'cc_salesforce_save_lead_additional_info';
  leadData.scheduleTest = Cookie.get('scheduleTest');

  if (global.ccUserInfo.attemptsAtInfoAjax === 1) {
    $.ajax({
        type: 'POST',
        url: ajaxurl,
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: leadData,
        cache: false,
        headers: { "cache-control": "no-cache" },
        success: function() {
        },
        error: function(result) {
          if (global.ccUserInfo.attemptsAtInfoAjax < 2) {
            saveLeadAdditionalInfo('');
          } else {
            global.dataLayer.push({ "error_data": result });
            global.dataLayer.push({ "error_message": "Ajax Error saving additional info to lead" });
            global.dataLayer.push({ "event": "ajax-error" });
          }
        },
      });
  }
}

export function retrieveAppointments() {
  //var testTextConfirmationCheckBox = Cookie.get('testTextConfirmationCheckBox');
  //var testTextConfirmationPopUp = Cookie.get('testTextConfirmationPopUp');
  //if(testTextConfirmationCheckBox && !testTextConfirmationPopUp){
    //saveCampaign('Text_v1');
    //showTextConsentCheckboxTest();
  //}else if(!testTextConfirmationCheckBox && testTextConfirmationPopUp){
    //var agreeText = "<p style'text-align:center;'>By confirming your consult, you agree to the <a href='/terms/' target='_blank'>terms and conditions</a> and that the individual is over the age of 18.</p>"
    //$('.field-agree-to-terms').append(agreeText);
    //$('#input_19_17').hide();
  //}
  showWaitingIcon('Retrieving Appointments');
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';
  // console.log(callingelement)
  // If this is the first time going through this function,
  // define a flag so we know if it's been repeated
  if (typeof global.ccUserInfo.attemptsAtAppointmentAjax === 'undefined') {
    global.ccUserInfo.attemptsAtAppointmentAjax = 0;
  }

  if (typeof global.ccUserInfo.model2eventfired === 'undefined') {
    global.ccUserInfo.model2eventfired = false;
  }

  // Step up the variable
  global.ccUserInfo.attemptsAtAppointmentAjax++;

  let leadData = getLeadData();
  var dt = new Date();
  // get current time
  dt.setHours(dt.getHours());
  // compare against a global set at initial appointment preload.
  if(global.expirationTime <= dt){
    // Force reload of appointments to prevent edge case of someone scheduling in the past.
    leadData.unfilteredAppointments = undefined;
  }else{
    // Within one hour timeframe, allow use of preloaded appointments
    leadData.unfilteredAppointments = JSON.stringify(global.unfilteredAppointments);
  }

  leadData.isS20Active =  global.ccUserInfo.isS20Active;
  leadData.model2ScoreInfo = JSON.stringify(global.ccUserInfo.model2ScoreInfo);
  leadData.scheduleTest = Cookie.get('scheduleTest');
  leadData.action = "cc_retrieve_appointments";
  /* eslint-disable */
  let shouldContinue;
  /* eslint-enable */

  var leadSegment,leadValue, leadSegment20, leadSegment5, collectionsPerLead;

  //if (global.ccUserInfo.attemptsAtAppointmentAjax === 1) {
    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: leadData,
      cache: false,
      headers: { "cache-control": "no-cache" },
      success: function(msg) {
        if((msg.schedulingDisabled != undefined && msg.schedulingDisabled == true) || (msg.appointments != undefined && msg.appointments == "No Appointments")){
          if(msg.appointments == "No Appointments"){
            global.dataLayer.push({'event':'No Apts Loaded'});
          }

          if(!global.ccUserInfo.model2eventfired){

            leadValue = msg.monetaryValue;
            collectionsPerLead = msg.collectionsPerLead;
            leadSegment = msg.leadSegment;
            leadSegment20 = msg.leadSegment20;
            leadSegment5 = msg.leadSegment5;
            global.dataLayer.push({ 'Lead Value': leadValue});
            global.dataLayer.push({ 'Lead Segment': leadSegment});
            global.dataLayer.push({ 'Lead Segment 20': leadSegment20});
            global.dataLayer.push({ 'Collections Per Lead': collectionsPerLead});
            global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
            global.ccUserInfo.leadSegment5 = parseFloat(msg.leadSegment5);
            global.ccUserInfo.leadSegment20 = parseFloat(msg.leadSegment20);
            global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
            global.ccUserInfo.leadScore = msg.leadScore;
            global.ccUserInfo.collectionsPerLead = msg.collectionsPerLead;
            flagGYIM();

            global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
            global.dataLayer.push({ 'collectionsPerLead':   global.ccUserInfo.collectionsPerLead});
            global.dataLayer.push({ 'orderNumber': createUuid()});
            global.dataLayer.push({
              'event': 'Scored and Segmented',
              'lead_id': global.ccUserInfo.guid,
              'lead_tier' : getLeadTier(),
              'lead_segment' : global.ccUserInfo.leadSegment20,
              });
            global.dataLayer.push({ 'event': 'lead_segment_floodlight20_offline', 'cat': 'clear0'});
            if(!global.switchedSteps){
              global.dataLayer.push({ 'event': 'personal_info_given'});
            }

            global.ccUserInfo.model2eventfired = true;
          }
          if(msg.under18 != undefined && msg.under18 == true){
            schedulingDisabledPopup(true);
            return true;
          }else if(msg.alreadyScheduled != undefined && msg.alreadyScheduled == true){
            schedulingDisabledExistingAppt();
            return true;
          }else{
            schedulingDisabledPopup();
            return true;
          }
        }

        if(global.ccUserInfo.metaConversionCampaign == true){
          schedulingDisabledCampaignPopup();
        }
        // if(msg.promptToCallWithSchedule){
        //   global.dataLayer.push( { 'event': 'Schedule Disabled' } );
        //   promptToCallWithScheduleDisplay();
        // }

        hideWaitingIcon();
        // console.log(msg);
        shouldContinue = true;
        let retrievedGUID;
        if (msg.size > 0) {
          if(!global.ccUserInfo.model2eventfired){
            global.ccUserInfo.model2eventfired = true;

            if(msg.leadSegment !== undefined || msg.leadSegment20 !== undefined || msg.leadSegment5 !== undefined){
              collectionsPerLead = msg.collectionsPerLead;
              leadValue = msg.monetaryValue;
              leadSegment = msg.leadSegment;
              leadSegment20 = msg.leadSegment20;
              leadSegment5 = msg.leadSegment5;
              global.dataLayer.push({ 'Lead Value': leadValue});
              global.dataLayer.push({ 'Lead Segment': leadSegment});
              global.dataLayer.push({ 'Lead Segment 20': leadSegment20});
              global.dataLayer.push({ 'Collections Per Lead': collectionsPerLead});
              global.ccUserInfo.leadSegment = parseFloat(msg.leadSegment);
              global.ccUserInfo.leadSegment5 = parseFloat(msg.leadSegment5);
              global.ccUserInfo.leadSegment20 = parseFloat(msg.leadSegment20);
              global.ccUserInfo.collectionsPerLead = msg.collectionsPerLead;

            }

            global.ccUserInfo.model2PilotCenter = msg.model2PilotCenter;
            global.ccUserInfo.leadScore = msg.leadScore;
            flagGYIM();

            global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
            global.dataLayer.push({ 'collectionsPerLead':   global.ccUserInfo.collectionsPerLead});
            global.dataLayer.push({ 'orderNumber': createUuid()});
            global.dataLayer.push({
              'event': 'Scored and Segmented',
              'lead_id': global.ccUserInfo.guid,
              'lead_tier' : getLeadTier(),
              'lead_segment' : global.ccUserInfo.leadSegment20,
              });
            global.dataLayer.push({ 'event': 'lead_segment_floodlight20_offline', 'cat': 'clear0'});
            if(!global.switchedSteps){
              global.dataLayer.push({ 'event': 'personal_info_given'});
            }

          }


          processAppointments(msg.appointments, msg.api_version);
          $( '.make-appointment .gform_next_button' ).attr("disabled",true);

          if( msg.Appointment_Scheduled__c == true && ! global.ccUserInfo.testing ){

            //Set the rescheduling flag
            global.ccUserInfo.reschedulingAppointment = true;
            if(msg.Can_Reschedule!== undefined && !msg.Can_Reschedule){
              schedulingDisabledExistingAppt();
              global.dataLayer.push({"error_data": msg});
              global.dataLayer.push({"error_message": "Lead trying to reschedule within 48 hours"});
              global.dataLayer.push({"event": "ajax-error"});
              return true;
            }else{
              $('#webform-component-messages .buttons').find("> div").hide();
              schedulingDisabledExistingAppt();
              return true;
            }

          }
        } else {
          if (typeof msg[0] !== 'undefined' && typeof msg[0].success !== 'undefined' && msg[0].success === true) {
            if(!global.ccUserInfo.model2eventfired){
              global.ccUserInfo.model2eventfired = true;
              if(msg[0].leadSegment  !== undefined || msg[0].leadSegment20 !== undefined || msg[0].leadSegment5 !== undefined){
                collectionsPerLead = msg.collectionsPerLead;
                leadValue = msg[0].monetaryValue;
                leadSegment = msg[0].leadSegment;
                leadSegment20 = msg.leadSegment20;
                leadSegment5 = msg.leadSegment5;
                global.dataLayer.push({ 'Lead Value': leadValue});
                global.dataLayer.push({ 'Lead Segment': leadSegment});
                global.dataLayer.push({ 'Lead Segment 20': leadSegment20});
                global.dataLayer.push({ 'Collections Per Lead': collectionsPerLead});
                global.ccUserInfo.leadSegment = parseFloat(msg[0].leadSegment);
                global.ccUserInfo.leadSegment5 = parseFloat(msg[0].leadSegment5);
                global.ccUserInfo.leadSegment20 = parseFloat(msg[0].leadSegment20);
                global.ccUserInfo.collectionsPerLead = msg[0].collectionsPerLead;
              }

              global.ccUserInfo.leadScore = msg[0].leadScore;
              flagGYIM();


              global.dataLayer.push({ 'scoreAndSegment': global.ccUserInfo.leadScore+global.ccUserInfo.leadSegment});
              global.dataLayer.push({ 'collectionsPerLead':   global.ccUserInfo.collectionsPerLead});
              global.dataLayer.push({ 'orderNumber': createUuid()});
              global.dataLayer.push({
                'event': 'Scored and Segmented',
                'lead_id': global.ccUserInfo.guid,
                'lead_tier' : getLeadTier(),
                'lead_segment' : global.ccUserInfo.leadSegment20,
                });
              global.dataLayer.push({ 'event': 'lead_segment_floodlight20_offline', 'cat': 'clear0'});
              if(!global.switchedSteps){
                global.dataLayer.push({ 'event': 'personal_info_given'});
              }

            }


            processAppointments(msg[0].appointments);


            global.ccUserInfo.esi = msg.esi;
            global.ccUserInfo.nTile = msg.nTile;
            global.ccUserInfo.consultPotential = msg.consultPotential;
          } else {
            var flag = false;

            if (typeof msg[0] !== 'undefined' &&
              (typeof msg[0].errors !== 'undefined') &&
              (typeof msg[0].errors[0] !== 'undefined') &&
              (typeof msg[0].errors[0].statusCode !== 'undefined') &&
              (msg[0].errors[0].statusCode === 'INVALID_EMAIL_ADDRESS')
            ) {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">That doesn\'t seem to be a valid email address. Please correct, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            } else if (global.ccUserInfo.attemptsAtAppointmentAjax < 2) {
              retrieveAppointments('');
            } else {
              $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
              flag = true;
            }

            if (flag) {
              global.dataLayer.push({ "error_data": msg });
              global.dataLayer.push({ "error_message": "Server Error looking up/ creating lead info" });
              global.dataLayer.push({ "event": "ajax-error" });
              shouldContinue = false;
            }
          }
        }
        if(msg == 'no auth token'){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }
      },
      error: function(result) {
        // If the ajax fails for some reason, try again
        if (global.ccUserInfo.attemptsAtAppointmentAjax < 2) {
          retrieveAppointments('');
        } else {
          hideWaitingIcon();
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">There was an issue connecting to our servers. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Ajax Error looking up/ creating lead info" });
          global.dataLayer.push({ "event": "ajax-error" });
        }
      },
    });
  //}
}

/**
 * Data that is sent out during step 2/3 of OS form
 */
export function getLeadData() {

  let leadData = {};

  if (global.ccUserInfo.guid !== '') {
    leadData.GUID = global.ccUserInfo.guid;
  }

  // set global Google Analytics information to append to leadData below
  setGlobalGAInfo();

  leadData.FirstName = global.ccUserInfo.firstName.trim();
  leadData.LastName = global.ccUserInfo.lastName.trim();
  leadData.Phone = global.ccUserInfo.phone;
  leadData.Email = global.ccUserInfo.email.trim();
  leadData.Address = global.ccUserInfo.address.trim();
  leadData.City = global.ccUserInfo.city.trim();
  leadData.State = global.ccUserInfo.state;
  leadData.Zip = global.ccUserInfo.zipcode;
  leadData.MarketingSource = 'CCWebsiteOSI';
  leadData.testing = global.ccUserInfo.testing;
  leadData.FewQuestions = global.ccUserInfo.fewQuestions;
  leadData.spokenLanguage = global.ccUserInfo.spokenLanguage;
  leadData.birthDate = global.ccUserInfo.birthDate;
  if(global.ga != 'undefined' && window.ga != undefined){
    leadData.GACLIENTID = global.ga.clientId;
    leadData.GAUSERID = global.ga.userId;
    leadData.GATRACKID = global.ga.trackingId;
    leadData.GCLID = global.ga.gclId;
  }

  leadData.GUID = global.ccUserInfo.guid;
  leadData.accountId = global.ccUserInfo.accountID;
  leadData.contactId = global.ccUserInfo.contactID;

  leadData.appointmentScheduled = global.ccUserInfo.appointmentScheduled;
  leadData.consultInventory = global.ccUserInfo.consultInventory;
  leadData.securityToken = global.securityToken;
  leadData.leadScore = global.ccUserInfo.leadScore;

  var parsed = queryString.parse(location.search);

  if(parsed.testLeadScore !== undefined){
    leadData.leadScore = parsed.testLeadScore;
  }

  if(parsed.testLeadScore !== undefined){
    leadData.testLeadScore = parsed.testLeadScore;
  }

  if(parsed.model2testing !== undefined){
    leadData.model2testing = true;
  }

  if(parsed.trans_id !== undefined && parsed.trans_id.indexOf('Metaconversion24ptc') > -1){
    global.ccUserInfo.metaConversionCampaign = true;
  }

  var testLeadSegment = parsed.testLeadSegment;
  if(testLeadSegment !== undefined){
    leadData.testLeadSegment = testLeadSegment;
  }

  var testLeadSegment5 = parsed.testLeadSegment5;
  if(testLeadSegment !== undefined){
    leadData.testLeadSegment5 = testLeadSegment5;
  }

  var testLeadSegment20 = parsed.testLeadSegment20;
  if(testLeadSegment20 !== undefined){
    leadData.testLeadSegment20 = testLeadSegment20;
  }

  var duplicateTest = parsed.duplicateTest;
  if(duplicateTest !== undefined){
    leadData.duplicateTest = true;
  }

  if (global.ccUserInfo.centerID !== '') {
    leadData.CenterId = global.ccUserInfo.centerID;
  }

  if(global.ccUserInfo.distanceToCenter != ''){
    leadData.distanceToCenter = global.ccUserInfo.distanceToCenter;
  }

  if (typeof global.ccUserInfo.availableCenters[global.ccUserInfo.centerID] !== 'undefined') {
    leadData.CenterTimeZone = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID].timezone;
    leadData.CenterState = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID].state;
  }

  var valueTrackNetwork = localStorage.getItem('valueTrackNetwork');
  if(valueTrackNetwork){
    leadData.valueTrackNetwork = valueTrackNetwork;
  }

  var valueTrackCampaignId = localStorage.getItem('valueTrackCampaignId');
  if(valueTrackCampaignId){
    leadData.valueTrackCampaignId = valueTrackCampaignId;
  }

  var valueTrackMatchType = localStorage.getItem('valueTrackMatchType');
  if(valueTrackMatchType){
    leadData.valueTrackMatchType = valueTrackMatchType;
  }


  // check for ADI page and Colocation
  if($('body.adi-schedule').length > 0){
    leadData.page = 'adi';
    leadData.referral_id = global.ccUserInfo.referral_id;
  }else if($('body.colocation-schedule').length > 0){
    leadData.page = 'colocation';
    leadData.referral_id = global.ccUserInfo.referral_id;
  }else{
    leadData.page = 'os';
  }

  leadData.trans_id = global.ccUserInfo.trans_id;

  if(global.ccUserInfo.retryCall){
    leadData.isRetry = true;
    if(global.ccUserInfo.retryId != undefined){
      leadData.retryId = global.ccUserInfo.retryId;
    }
  }

  return leadData;
}

function getLeadTier(){
  if(global.ccUserInfo.leadSegment20 <= 20 && global.ccUserInfo.leadSegment20 >=15){
    return 'T1';
  }else if(global.ccUserInfo.leadSegment20 <= 14 && global.ccUserInfo.leadSegment20 >= 8){
    return 'T2';
  }else{
    return 'T3';
  }
}
